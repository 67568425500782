import React from 'react'
import { Typography, Button, styled } from '@material-ui/core'

export default function ChatBox () {
    return (
        <CustomTypingArea>
            <div className="chatBox">
                <div style={{ width: "100%", display: 'flex', justifyContent: "flex-start" }}>
                    <div className="message" style={{ gap: "8px" }}>
                        <Typography>Max Bot</Typography>
                        <Typography component={"h1"}>
                            Hi, I am Max. A digital assistant to help you with your journey today.
                        </Typography>
                        <Typography component={"h1"}>
                            What can we help with today?
                        </Typography>
                        <span className="typography" style={{ color: "#94A3B8", fontSize: "12px" }}>{"09:45"}</span>
                    </div>
                </div>
                <div style={{ width: "100%", display: 'flex', justifyContent: "flex-end" }}>
                    <div className="message" style={{ gap: "8px", alignItems: "flex-end" }}>
                        <Typography>You</Typography>
                        <Typography component={"h1"} style={{ background: "#51ABB3", color: "#fff", fontWeight: 700 }}>
                            I want some prescriptions about my headache
                        </Typography>
                        <span className="typography" style={{ color: "#94A3B8", fontSize: "12px" }}>{"09:46"}</span>
                    </div>
                </div>
                <Button style={{ margin: "0 auto" }}>Go back to pre-programmed flow</Button>
            </div>
            <div className="message-composer">
                <input type="text" />
                <Button>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.5 16.1326V3.86631C2.5 3.52033 2.6363 3.25677 2.9089 3.0756C3.18149 2.89507 3.46923 2.86771 3.77212 2.99352L17.7654 9.12664C18.144 9.29962 18.3333 9.59055 18.3333 9.99943C18.3333 10.4083 18.144 10.6992 17.7654 10.8722L3.77212 17.0053C3.46923 17.1311 3.18149 17.1035 2.9089 16.9223C2.6363 16.7418 2.5 16.4785 2.5 16.1326ZM4.31731 14.7172L15.0849 9.99943L4.31731 5.28164V8.58409L9.76925 9.99943L4.31731 11.4148V14.7172Z" fill="white" />
                    </svg>
                </Button>
            </div>
        </CustomTypingArea>
    )
}

const CustomTypingArea = styled("div")({
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 108px)",
    boxSizing: "border-box",

    "& .chatBox": {
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        padding: "24px",
        flex: "1 1 0%",
        color: "#0F172A",
        fontFamily: "Cairo",
        fontWeight: 400,
        overflowY: "auto"
    },
    "& .typography": {
        color: "#0F172A",
        fontFamily: "Cairo",
        fontWeight: 400,
        fontSize: "14px"
    },
    "& .chatBox button": {
        fontFamily: "Cairo",
        fontWeight: 400,
        fontSize: "14px",
        color: "#64748B",
        borderRadius: "50px",
        padding: "8px 16px",
        width: "276px",
        height: "40px",
        border: "2px solid #F2F2F2"
    },
    "& .chatBox h1": {
        color: "#0F172A",
        fontFamily: "Cairo",
        fontWeight: 400,
        background: "#E2E8F0",
        padding: "12px 14px",
        borderRadius: "12px",
        fontSize: "14px",
        width: "fit-content"
    },
    "& .chatBox .message": {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "283px"
    },
    "& .chatBox .message p": {
        color: "#94A3B8",
        fontFamily: "Cairo",
        fontWeight: 700,
        fontSize: "14px"
    },
    "& .message-composer": {
        display: "flex",
        alignItems: "center",
        padding: "6px",
        boxSizing: "border-box",
        height: "56px",
        gap: "6px",
        width: "100%",
    },
    "& .message-composer input": {
        border: "1px solid #CBD5E1",
        borderRadius: "12px",
        padding: "12px 14px",
        boxShadow: "0px 2px 8px 0px #00000014",
        width: "100%",
        height: "44px",
        outline: "none"
    },
    "& .message-composer button": {
        width: "44px",
        height: "44px",
        borderRadius: "12px",
        background: "#51ABB3"
    }
})
