Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "chatbot5";
exports.labelBodyText = "chatbot5 Body";
exports.buttonTitleText = "Home";

exports.btnExampleTitle = "CLICK ME";
exports.formsEndPoint = "bx_block_custom_forms/custom_forms/active_forms";
exports.userGuideEndPoint = "bx_block_cfinteractivewhiteboardtoolforchatbotmaker2/guides/active_guides";
exports.connectorCardsEndPoint = "bx_block_chatbot6/connector_cards";
exports.chatbotEndpoint = "bx_block_chatbot6/chatbots";
exports.updateSource = "bx_block_chatbot6/update_source";
exports.createTemplateEndpoint = "bx_block_chatbot6/card_templates";
exports.deleteMultipleCards = "bx_block_chatbot6/connector_cards/delete_multiple_card";
// Customizable Area End