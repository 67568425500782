import React from "react";
import {
    CircularProgress,
    IconButton,
    InputLabel,
    OutlinedInput,
    ThemeProvider,
    Typography,
    createTheme,
    makeStyles,
    styled,
} from "@material-ui/core";
import {
    theme as cardTheme,
} from "../theme.web";
import { AddCircleOutlineRounded, Clear } from "@material-ui/icons";
import MultipleCustomSelect from "../../../../components/src/MultipleCustomSelect.web";
import { Formik } from "formik";
import * as yupSchema from "yup";
import CardFooter from "./CardFooter.web";
import CardHeader from "./CardHeader.web";
import MessageTextArea from "./MessageTextArea.web";
import ShareUserGuideOrEmbedFormController, { ShareUserGuideOrEmbedFormProps } from "./ShareUserGuideOrEmbedFormController";

const shareUserGuideSchema = yupSchema.object().shape({
    name: yupSchema.string().trim().required('Please add a name'),
    message: yupSchema.string().trim().required('Please add a message'),
    selectedOptions: yupSchema.array()
        .of(yupSchema.string())
        .required('Please select user guide')
        .min(1, 'Please select atleast one user guide'),
});

const embedFormSchema = yupSchema.object().shape({
    message: yupSchema.string().trim().required('Please add a message'),
    name: yupSchema.string().trim().required('Please add a name'),
    selectedOptions: yupSchema.array()
        .of(yupSchema.string())
        .required('Please select embed form')
        .min(1, 'Please select atleast one embed form'),
});

const {
    MuiFormLabelRootStyle,
    MuiTypographyStyle,
    MuiOutlinedInputStyle,
    MuiInputBaseRootStyle,
} = cardTheme

const theme = createTheme({
    typography: MuiTypographyStyle,
    overrides: {
        MuiFormLabel: {
            root: MuiFormLabelRootStyle,
        },
        MuiOutlinedInput: MuiOutlinedInputStyle,
        MuiInputBase: MuiInputBaseRootStyle,
        MuiIconButton: {
            root: {
                padding: 0,
                '&:hover': {
                    backgroundColor: "#E5E9FF",
                },
            },
        },
    },
})

const SpinnerContainer = styled("div")({
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    zIndex: 3,
})


const SelectedUserGuideContainer = styled("div")({
    display: "flex",
    gap: 8,
    flexWrap: "wrap",
})

const SelectedUserGuide = styled("div")({
    padding: "4px 8px",
    backgroundColor: "#E5E9FF",
    display: "flex",
    gap: 4,
    borderRadius: 26,
    width: "max-content",
})

const ContainerBox = styled("div")({
    width: 440,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#fff",
    scrollbarWidth: "none",
    overflowY: "auto",
    '&::-webkit-scrollbar': {
        width: 0,
    },
})

const ContentBox = styled("div")({
    backgroundColor: "#FFFFFF",
    padding: "0px 48px 24px",
    display: "flex",
    gap: 12,
    flexDirection: "column",
    flexGrow: 1,
})

const FormControl = styled("div")({
    display: "flex",
    gap: 4,
    flexDirection: "column",
    '&. gap-8': {
        gap: 8,
    },
})

const FormErrorOrInfo = styled("div")({
    display: "flex",
    gap: 4,
    justifyContent: "space-between",
    '&.error': {
        color: "#D97706",
    },
})

const CreateUserGuideButton = styled("button")({
    all: "unset",
    display: "flex",
    alignItems: "center",
    gap: 8,
    cursor: "pointer",
    lineHeight: "24px",
    fontFamily: "Cairo",
    fontSize: 16,
    fontWeight: 700,
    color: "#64748B",
    '&[aria-disabled="true"]': {
        color: "#94A3B8",
        pointerEvents: "none",
    },
})

export default class ShareUserGuideOrEmbedForm extends ShareUserGuideOrEmbedFormController {

    constructor(props: ShareUserGuideOrEmbedFormProps) {
        super(props)
    }

    hasError = (error: string | string[] | undefined, touched: boolean | undefined) => {
        return !!error && !!touched
    }

    selectedOptionsWithLabel = (selectedValues: string[]) => {
        const selectedOptionsSet = new Set(selectedValues)
        const selectedOptionsWithLabel = this.state.options.filter(option => selectedOptionsSet.has(option.value))
        return selectedOptionsWithLabel
    }

    render() {
        const currentType = this.constVal[this.props.type]

        return (
            <ThemeProvider theme={theme} >
                {
                    this.state.cardLoading && (
                    <SpinnerContainer>
                        <CircularProgress style={{height: 40, width: 40, color: "#51ABB3"}} />
                    </SpinnerContainer>
                    )
                }
                <Formik
                    initialValues={{ name: this.props.title ?? "", message: this.props.message ?? "", selectedOptions: this.props.selectedOptions ?? [] }}
                    onSubmit={(values) => this.props.onSave({ id: this.props.id, type: this.props.type, ...values })}
                    validationSchema={this.props.type === "share_user_guide" ? shareUserGuideSchema : embedFormSchema}
                >
                    {({ getFieldProps, errors, touched, values, handleSubmit, setFieldValue }) => (
                        <ContainerBox>
                            <CardHeader id={this.props.id} data-testid="cardHeader" onClose={this.props.onClose} title={currentType.title} />
                            <ContentBox>
                                <FormControl>
                                    <InputLabel htmlFor="name" >Name*</InputLabel>
                                    <OutlinedInput
                                        inputProps={{ ...getFieldProps("name"), maxLength: 50 }}
                                        placeholder={currentType.namePlaceholder}
                                        error={this.hasError(errors.name, touched.name)}
                                        fullWidth
                                        id="name"
                                        name="name"
                                    />
                                    <FormErrorOrInfo>
                                        {
                                            this.hasError(errors.name, touched.name) && <Typography className="error" variant="subtitle2" >{errors.name}</Typography>
                                        }
                                        <Typography variant="subtitle2" className="ml-auto" >{values.name.trim().length}/50</Typography>
                                    </FormErrorOrInfo>
                                </FormControl>
                                <MessageTextArea
                                    getFieldProps={getFieldProps}
                                    hasMessageError={this.hasError(errors.message, touched.message)}
                                    messageErrorText={errors.message}
                                    value={values.message}
                                    botContext={this.props.botContext}
                                    setFieldValue={setFieldValue}
                                />
                                <FormControl className="gap-8" >
                                    <InputLabel id="multi-select-option" >{currentType.selectLabel}</InputLabel>
                                    <MultipleCustomSelect
                                        selectBoxWidth={344}
                                        labelId="multi-select-option"
                                        id="multi-select-popover"
                                        placeholder={currentType.selectPlaceholder}
                                        value={values.selectedOptions}
                                        options={this.state.options}
                                        onChange={(value: string[]) => setFieldValue("selectedOptions", value)}
                                        disabled={this.state.options.length === 0}
                                        error={this.hasError(errors.selectedOptions, touched.selectedOptions)}
                                        hasSearch={true}
                                        searchPlaceholder="Search..."
                                    />
                                    {
                                        this.hasError(errors.selectedOptions, touched.selectedOptions) && <Typography className="error" variant="subtitle2" >{errors.selectedOptions}</Typography>
                                    }
                                </FormControl>
                                {
                                    values.selectedOptions.length > 0 && (
                                        <FormControl data-testid="selected-options" className="gap-8" >
                                            <Typography variant="body2" >{`${currentType.selectedText} (${values.selectedOptions.length})`}</Typography>
                                            <SelectedUserGuideContainer>
                                                {
                                                    this.selectedOptionsWithLabel(values.selectedOptions).map((option) => (
                                                        <SelectedUserGuide key={option.value}>
                                                            <Typography data-testid={option.label} component="span" variant="body2" className="primary" >{option.label}</Typography>
                                                            <IconButton
                                                                onClick={() => {
                                                                    const newSelectedOptions = values.selectedOptions.filter(item => item !== option.value)
                                                                    setFieldValue("selectedOptions", newSelectedOptions)
                                                                }}
                                                                aria-label={`remove ${option.label}`}
                                                                data-testid={`remove ${option.label}`}
                                                            >
                                                                <Clear htmlColor="#51ABB3" style={{ height: 16, width: 16 }} />
                                                            </IconButton>
                                                        </SelectedUserGuide>
                                                    ))
                                                }
                                            </SelectedUserGuideContainer>
                                        </FormControl>
                                    )
                                }
                                <CreateUserGuideButton id="create" onClick={this.handleCreateUserGuideNavigation}>
                                    <AddCircleOutlineRounded htmlColor="#64748B" style={{ height: 20, width: 20 }} />
                                    {currentType.createNewText}
                                </CreateUserGuideButton>
                            </ContentBox>
                            <CardFooter onClose={this.props.onDrawerClose} onSaveClick={() => handleSubmit()} loading={this.props.onSaveLoading} />
                        </ContainerBox>
                    )}
                </Formik>
            </ThemeProvider>
        )
    }
}
