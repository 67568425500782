import {
    Avatar,
    Box,
    ThemeProvider,
    Typography,
    createTheme,
    makeStyles,
    styled
} from "@material-ui/core";
import { RefreshRounded, SubdirectoryArrowRight } from "@material-ui/icons";
import React from "react";
import { defaultChatLogo } from "../assets";

const useStyles = makeStyles({
    container: {
        width: "30%",
        display: "flex",
        flexDirection: "column",
    },
    headerContainer: {
        display: "flex",
        gap: 12,
        padding: 24,
        alignItems: "center",
    },
    chatBodyContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 12,
        padding: 24,
        borderRadius: "0px 0px 24px 24px",
        flex: 1,
        '& .text-center': {
            textAlign: "center",
        },
    },
    chatBotEndContainer: {
        display: "flex",
        justifyContent: "center",
        gap: 8,
    },
    chatMessageStack: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        alignItems: "flex-start",
        '&.user-stack': {
            alignItems: "flex-end"
        },
    },
    chatMessage: {
        padding: '12px 14px 12px 14px',
        borderRadius: 12,
        maxWidth: 283,
        minWidth: 80,
        display: "flex",
        flexDirection: "column",
        gap: 10,
        boxSizing: "border-box",
        '&.bot': {
            color: "#0F172A",
            backgroundColor: "#E2E8F0",
        },
        '&.user': {
            color: "#FFFFFF",
            backgroundColor: "#51ABB3",
        },
    },
    userNameWithTime: {
        padding: "0px 16px",
        textAlign: "right",
        color: "#94A3B8",
        fontFamily: "Cairo",
        fontSize: 14,
        lineHeight: "18px",
        fontWeight: 700,
        boxSizing: "border-box",
        '&.time': {
            fontSize: 12,
            fontWeight: 400,
        },
        '&.bot': {
            textAlign: "left",
        },
    },
})

const theme = createTheme({
    typography: {
        fontFamily: "Cairo",
    },
    overrides: {
        MuiAvatar: {
            root: {
                width: 60,
                height: 60
            },
        },
        MuiTypography: {
            h5: {
                color: "#FFFFFF",
                fontWeight: 400,
                fontSize: 20,
                lineHeight: "18px",
            },
            body1: {
                color: "#0F172A",
                fontWeight: 400,
                fontSize: 14,
                lineHeight: "24px",
                '&.white': {
                    color: "#FFFFFF",
                    fontWeight: 700,
                },
            },
        },
    },
})

type User = "bot" | "user";

type ChatPreviewProps = {
    headerColor: string;
    backgroundColor: string;
    headerLabel: string;
    headerImageURL: string | null;
    userActions: string[];
}

type ChatMessageProps = {
    type: User;
    children: React.ReactNode;
    headerColor?: string;
}

type UserNameWithTimeInChatProps = {
    type: User;
    isTime?: boolean;
    children: React.ReactNode;
}


export default function ChatPreview({
    headerColor,
    backgroundColor,
    headerLabel,
    headerImageURL,
    userActions
}: ChatPreviewProps) {
    const classes = useStyles()
    return (
        <ThemeProvider theme={theme}>
            <div className={classes.container}>
                <div id="chat-header" className={classes.headerContainer} data-color={headerColor} style={{ backgroundColor: headerColor }} >
                    <Avatar alt="chatbot-picture" src={headerImageURL ?? defaultChatLogo} />
                    <Box>
                        <Typography variant="h5" >Chat with</Typography>
                        <Typography id="header-title" variant="h5" style={{ fontWeight: 700, marginTop: 5 }}>
                            {headerLabel || 'Max Support Chatbot'}
                        </Typography>
                    </Box>
                </div>
                <div id="chat-body" data-color={backgroundColor} className={classes.chatBodyContainer} style={{ backgroundColor }} >
                    <ChatMessagesStack type="bot">
                        <UserNameWithTimeInChat type="bot" >Max Bot</UserNameWithTimeInChat>
                        <ChatMessage data-test-id="bot-message" type="bot">
                            <Typography>
                                Hi, I am Max. A digital assistant to help you with your journey today.
                            </Typography>
                        </ChatMessage>
                        <ChatMessage type="bot">
                            <Typography>
                                What can we help with today?
                                <br />
                                Please select 1 option below:
                            </Typography>
                            <ChatSelectOptions>
                                <ChatOption>Appointments</ChatOption>
                                <ChatOption>NHS App</ChatOption>
                                <ChatOption>Test Results</ChatOption>
                                <ChatOption>Prescriptions</ChatOption>
                            </ChatSelectOptions>
                        </ChatMessage>
                        <UserNameWithTimeInChat type="bot" isTime >09:45</UserNameWithTimeInChat>
                    </ChatMessagesStack>
                    <ChatMessagesStack type="user">
                        <UserNameWithTimeInChat type="user" >You</UserNameWithTimeInChat>
                        <ChatMessage headerColor={headerColor} data-test-id="user-message" type="user">
                            <Typography className="white" >Prescriptions</Typography>
                        </ChatMessage>
                        <UserNameWithTimeInChat type="user" isTime >09:46</UserNameWithTimeInChat>
                    </ChatMessagesStack>
                    <ChatMessagesStack data-test-id="user-message-stack" type="user">
                        <UserNameWithTimeInChat data-test-id="user-name" type="user" >You</UserNameWithTimeInChat>
                        <ChatMessage headerColor={headerColor} type="user">
                            <Typography className="white text-center" >Yes</Typography>
                        </ChatMessage>
                        <UserNameWithTimeInChat data-test-id="user-message-time" type="user" isTime >09:46</UserNameWithTimeInChat>
                    </ChatMessagesStack>
                    <ChatMessagesStack data-test-id="bot-message-stack" type="bot">
                        <UserNameWithTimeInChat type="bot" >Max Bot</UserNameWithTimeInChat>
                        <ChatMessage type="bot">
                            <Typography>
                                What can we help with today?
                                <br />
                                Please select 1 option below:
                            </Typography>
                            <ChatSelectOptions>
                                <ChatOption>Appointments</ChatOption>
                                <ChatOption>NHS App</ChatOption>
                                <ChatOption>Test Results</ChatOption>
                                <ChatOption>Prescriptions</ChatOption>
                                <ChatOption>General  Enquiries</ChatOption>
                            </ChatSelectOptions>
                        </ChatMessage>
                        <UserNameWithTimeInChat data-test-id="bot-message-time" type="bot" isTime >09:46</UserNameWithTimeInChat>
                    </ChatMessagesStack>
                    <div className={classes.chatBotEndContainer}>
                        {
                            userActions.includes("go_back_to_previous_message") && (
                                <ChatbotEndButton component="button" >
                                    <SubdirectoryArrowRight />
                                    Go back
                                </ChatbotEndButton>
                            )
                        }
                        {
                            userActions.includes("restart_conversation") ? (
                                <ChatbotEndButton component="button" >
                                    <RefreshRounded />
                                    Retry
                                </ChatbotEndButton>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </ThemeProvider>
    )
}

const ChatMessagesStack = ({ type, children }: ChatMessageProps) => {
    const classes = useStyles()
    return (
        <div data-test-id="message-stack" className={`${classes.chatMessageStack} ${type === "user" ? "user-stack" : ""}`} >
            {children}
        </div>
    )
}

const ChatMessage = ({
    type,
    children,
    headerColor
}: ChatMessageProps) => {
    const classes = useStyles()
    return (
        <div data-type={`by-${type}`} style={{backgroundColor: headerColor}} className={`${classes.chatMessage} ${type === "bot" ? "bot" : "user"}`} >
            {children}
        </div>
    )
}

const ChatSelectOptions = styled(Box)(() => ({
    display: 'flex',
    rowGap: 10,
    columnGap: 4,
    flexWrap: 'wrap'
}))

const ChatOption = styled(Box)(() => ({
    padding: "8px 20px",
    backgroundColor: "#FFFFFF",
    border: "2px solid #F2F2F2",
    color: "#64748B",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "14px",
    fontFamily: "Cairo",
    borderRadius: 50,
    boxSizing: "border-box",
}))

const ChatbotEndButton = styled(Box)(() => ({
    all: "unset",
    display: "flex",
    gap: 16,
    alignItems: "center",
    padding: "8px 32px 8px 16px",
    border: "2px solid #F2F2F2",
    borderRadius: 50,
    color: "#64748B",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "14px",
    fontFamily: "Cairo",
    boxSizing: "border-box",
}))

const UserNameWithTimeInChat = ({
    type,
    isTime,
    children
}: UserNameWithTimeInChatProps) => {
    const classes = useStyles()
    return (
        <div data-type={`by-${type}`} className={`${classes.userNameWithTime} ${type === "bot" ? "bot" : ""} ${isTime ? "time" : ""}`} >
            {children}
        </div>
    )
}
