import React from "react";
import {
    InputLabel,
    OutlinedInput,
    ThemeProvider,
    Typography,
    createTheme,
    makeStyles,
} from "@material-ui/core";
import {
    theme as cardTheme,
} from "../theme.web";
import CardHeader from "./CardHeader.web";
import CardFooter from "./CardFooter.web";
import MessageTextArea from "./MessageTextArea.web";
import * as yupSchema from "yup";
import { useFormik } from "formik";
import { url_regex } from "./ShareLink.web";

const {
    drawerCard,
    MuiFormLabelRootStyle,
    MuiButtonRootStyle,
    MuiTypographyStyle,
    MuiOutlinedInputStyle,
    MuiInputBaseRootStyle,
} = cardTheme

const theme = createTheme({
    typography: MuiTypographyStyle,
    overrides: {
        MuiButton: {
            text: {
                padding: "10px 16px",
            },
            root: MuiButtonRootStyle,
        },
        MuiFormLabel: {
            root: MuiFormLabelRootStyle,
        },
        MuiInputBase: MuiInputBaseRootStyle,
        MuiOutlinedInput: MuiOutlinedInputStyle,
    }
})

const useVideoEmbeddingStyles = makeStyles({
    container: drawerCard.container,
    drawerContent: drawerCard.drawerContent,
    formControl: drawerCard.formControl,
    formErrorOrInfo: drawerCard.formErrorOrInfo,
})

const videoURLRegex = /^https:\/\/(?:vimeo\.com|www\.youtube\.com|youtu\.be)\/[a-zA-Z0-9-_.~%]+(?:\/[a-zA-Z0-9-_.~%]*)*(?:\?[a-zA-Z0-9-_.~%=&,]*)?(?:#[\w-]*)?$/

const videoEmbeddingValidationSchema = yupSchema.object().shape({
    name: yupSchema.string().trim().required('Please add a name'),
    message: yupSchema.string().trim().required('Please add a message'),
    videoURL: yupSchema.string().trim().required('Please add a video URL').matches(videoURLRegex, {message: 'Please enter a valid video URL'})
});

export type ShareVideoFormValues = {
    name: string;
    message: string;
    videoURL: string;
    id?: number;
}

export type ShareVideoProps = {
    onClose: () => void;
    onSave: (values: ShareVideoFormValues) => void;
    loading: boolean;
    title?: string;
    message?: string;
    videoURL?: string;
    id?: number;
    onDrawerClose: () => void;
    botContext: string[];
}

export default function ShareVideo({
    onClose, 
    onSave, 
    loading,
    title="",
    message="",
    videoURL="https://",
    id,
    onDrawerClose,
    botContext
}: ShareVideoProps) {
    const classes = useVideoEmbeddingStyles()
    const {getFieldProps, errors, values, touched, handleSubmit, setFieldValue} = useFormik({
        initialValues: {name: title, message, videoURL},
        validationSchema: videoEmbeddingValidationSchema,
        onSubmit: (values) => onSave({id, ...values})
    })

    const nameError = !!errors.name && !!touched.name
    const messageError = !!errors.message && !!touched.message
    const videoURLError = !!errors.videoURL && !!touched.videoURL

    return (
        <ThemeProvider theme={theme} >
            <div className={classes.container}>
                <CardHeader id={id} data-testid="cardHeader" onClose={onClose} title="Share video" />
                <div className={classes.drawerContent}>
                    <div className={classes.formControl}>
                        <InputLabel htmlFor="name" >Name*</InputLabel>
                        <OutlinedInput 
                            id="name"
                            name="name"
                            value={values.name}
                            error={nameError}
                            inputProps={{ ...getFieldProps("name"), maxLength: 50 }}
                            placeholder="Send video card"
                        />
                        <div className={classes.formErrorOrInfo} >
                            {nameError && <Typography variant="subtitle2" className="error">{errors.name}</Typography>}
                            <Typography variant="subtitle2" className="ml-auto" >{values.name.trim().length}/50</Typography>
                        </div>
                    </div>
                    <MessageTextArea 
                        getFieldProps={getFieldProps}
                        hasMessageError={messageError}
                        messageErrorText={errors.message}
                        value={values.message}
                        botContext={botContext}
                        setFieldValue={setFieldValue}
                        data-testid="share-video-message"
                    />
                    <div className={`${classes.formControl} gap-8`}>
                        <InputLabel htmlFor="videoURL">Video URL*</InputLabel>
                        <Typography variant="subtitle2">Youtube or Vimeo links are supported</Typography>
                        <OutlinedInput 
                            id="videoURL"
                            name="videoURL"
                            value={values.videoURL}
                            error={videoURLError}
                            inputProps={{ ...getFieldProps("videoURL") }}
                            placeholder="Type your video URL here"
                        />
                        {videoURLError && <Typography variant="subtitle2" className="error">{errors.videoURL}</Typography>}
                    </div>
                </div>
                <CardFooter onClose={onDrawerClose} onSaveClick={() => handleSubmit()} loading={loading} />
            </div>
        </ThemeProvider>
    )
}
