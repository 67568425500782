import React, { useRef, useState } from "react";
import { Popover, makeStyles } from "@material-ui/core"
import { CheckRounded, KeyboardArrowDown, Search } from "@material-ui/icons";

export const useCustomSelectStyles = makeStyles({
    root: {
        position: "relative"
    },
    select: {
        padding: "16px 8px",
        border: "1px solid #CBD5E1",
        backgroundColor: "#FFF",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 6,
        borderRadius: 8,
        boxSizing: "border-box",
        cursor: "pointer",
    },
    error: {
        borderColor: "#F59E0B",
    },
    value: {
        fontWeight: 400,
        fontFamily: "Cairo",
        fontSize: 16,
        lineHeight: "24px",
        color: "#64748B",
    },
    listWithSearchboxContainer: {},
    listBox: {
        display: "flex",
        flexDirection: "column",
        margin: 0,
        listStyle: "none",
        padding: 0,
        maxHeight: 143,
        overflowY: "auto",
        scrollbarWidth: "thin",
        scrollbarColor: "#64748B #FFFFFF",
    },
    option: {
        padding: "12px 16px",
        fontFamily: "Inter",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "24px",
        color: "#0F172A",
        cursor: "pointer",
        display: "flex",
        gap: 10,
        alignItems: "center",
        justifyContent: "space-between",
        '&:hover': {
            backgroundColor: "#F3EBFF",
        },
        '&:first-child': {
            borderRadius: "8px 8px 0 0",
        },
        '&:last-child': {
            borderRadius: "0 0 8px 8px",
        },
    },
    searchBoxList: {
        padding: "8px 16px",
    },
    searchBox: {
        display: "flex",
        alignItems: "center",
        gap: 10,
        padding: 6,
        border: "1px solid #CBD5E1",
        borderRadius: 98,
    },
    searchInput: {
        all: "unset",
        fontFamily: "Inter",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "24px",
        color: "#64748B",
        height: "24px",
    },
})

const paperPropsStyle: React.CSSProperties = {
    overflow: "hidden",
    maxHeight: "none",
    border: "1px solid #CBD5E1",
    backgroundColor: "#FFF",
    boxShadow: "0px 2px 8px 0px #00000014",
    boxSizing: "border-box",
    borderRadius: 8,
}

type NoSearchSelect = {
    hasSearch?: false;
    searchPlaceholder?: string;
}

type SearchSelect = {
    hasSearch: true;
    searchPlaceholder: string;
}

export type Option = {
    label: string;
    value: string;
}

export type CustomSelectProps = {
    labelId?: string;
    id: string;
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
    containerStyle?: React.CSSProperties;
    options: Option[];
    selectBoxWidth: string | number;
    renderOption?: (option: Option) => React.ReactNode;
    renderSelectedValue?: (selectedOption: Option) => React.ReactNode;
    selectClassName?: string;
    optionClassName?: string;
    disabled?: boolean;
    error?: boolean;
} & (NoSearchSelect | SearchSelect)


const CustomSelect = ({
    labelId,
    id,
    placeholder,
    value,
    hasSearch,
    searchPlaceholder,
    options,
    onChange,
    selectBoxWidth,
    renderOption,
    renderSelectedValue,
    optionClassName = "",
    selectClassName = "",
    disabled = false,
    error = false,
}: CustomSelectProps) => {
    const classes = useCustomSelectStyles()
    const comboboxRef = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState<boolean>(false)
    const [customSelectOptions, setCustomSelectOptions] = useState<Array<Option>>(options)

    const handleOpenPopover = () => {
        if (disabled) {
            return;
        }
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        setCustomSelectOptions(options)
    }

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchText = e.target.value;
        if (searchText.trim().length === 0) {
            setCustomSelectOptions(options)
            return;
        }
        const newOptions = customSelectOptions.filter(option => option.label.toLowerCase().includes(searchText.toLowerCase()))
        setCustomSelectOptions(newOptions)
    }

    const onListBoxClick = (option: Option) => () => {
        onChange(option.value)
        setOpen(false)
        setCustomSelectOptions(options)
    }

    const isIconDistanceGreaterThan241 = comboboxRef.current
        ? (window.innerHeight - comboboxRef.current.getBoundingClientRect().bottom) > 241
        : false;

    let selectedValue = options.find(option => option.value === value);
    
    return (
        <div id={id} className={classes.root} style={{ width: selectBoxWidth }} >
            <div ref={comboboxRef} data-error={error} aria-disabled={disabled} role="combobox" aria-expanded={open} aria-labelledby={labelId} aria-haspopup="listbox" className={`${classes.select} ${selectClassName} ${error ? classes.error : ""}`} onClick={handleOpenPopover} >
                {placeholder && !selectedValue && <span data-testid={id} className={classes.value}>{placeholder}</span>}
                {selectedValue && (
                    <span id="selected-value" data-testid={id} className={classes.value}>
                        {renderSelectedValue ? renderSelectedValue(selectedValue) : selectedValue.label}
                    </span>
                )}
                <KeyboardArrowDown htmlColor="#64748B" />
            </div>
            <Popover
                id={"popover" + "-" + id}
                open={open}
                anchorEl={comboboxRef.current}
                anchorOrigin={{
                    vertical: isIconDistanceGreaterThan241 ? 'bottom' : 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: isIconDistanceGreaterThan241 ? 'top' : 'bottom',
                    horizontal: 'center',
                }}
                onClose={handleClose}
                PaperProps={{ style: { width: selectBoxWidth, ...paperPropsStyle } }}
                aria-expanded={open}
            >
                <div className={classes.listWithSearchboxContainer} >
                    {
                        hasSearch && (
                            <div className={classes.searchBoxList} >
                                <div className={classes.searchBox}>
                                    <Search htmlColor="#94A3B8" />
                                    <input
                                        type="text"
                                        className={classes.searchInput}
                                        placeholder={searchPlaceholder}
                                        onChange={handleSearchInputChange}
                                        aria-label="search"
                                    />
                                </div>
                            </div>
                        )
                    }
                    <ul className={classes.listBox} role="listbox">
                        {
                            customSelectOptions.map((option) => {
                                const isOptionSelected = value === option.value
                                return (
                                    <li
                                        key={option.value}
                                        onClick={onListBoxClick(option)}
                                        data-value={option.value}
                                        className={`${classes.option} ${optionClassName}`}
                                        role="option"
                                        aria-selected={isOptionSelected}
                                    >
                                        {renderOption ? renderOption(option) : option.label}
                                        {isOptionSelected && <CheckRounded htmlColor="#51ABB3" />}
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </Popover>
        </div>
    )
}

export default CustomSelect
