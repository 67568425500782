import React, { useState } from "react";
import { 
    ThemeProvider, 
    createTheme, 
    Box, 
    Typography, 
    InputLabel, 
    OutlinedInput, 
    TextareaAutosize, 
    Checkbox, 
    makeStyles,
} from "@material-ui/core";
import { useFormik } from "formik";
import {
    theme as cardTheme
} from "../theme.web";
import UncheckedIcon from "./UncheckedIcon.web";
import CheckedIcon from "./CheckedIcon.web";
import MaximumOptionError from "./MaximumOptionError.web";
import CardHeader from "./CardHeader.web";
import MessageTextArea from "./MessageTextArea.web";
import CardFooter from "./CardFooter.web";
import { schema } from "./schema"


const {
    MuiFormLabelRootStyle,
    MuiTypographyStyle,
    MuiOutlinedInputRootStyle,
    MuiInputBaseRootStyle,
    drawerCard,
} = cardTheme


export type DropdownForm = {
    title: string;
    message: string;
    saveIntoBotContext?: boolean;
    fieldName: string;
    options: string[];
    id?: number;
}
export type DropdownProps = {
    onClose: () => void;
    onSave: (values: DropdownForm) => void;
    onDrawerClose: () => void;
    loading: boolean;
    title?: string;
    message?: string;
    id?: number;
    fieldName?: string;
    options?: string[];
    botContext: string[];
}


const useStyles = makeStyles({
    container: drawerCard.container,
    drawerContent: drawerCard.drawerContent,
    textArea: drawerCard.textArea,
    botContainer: {
        padding: "16px 8px 16px 16px",
        display: "flex",
        flexDirection: "column",
        gap: 12,
        borderRadius: 8,
        backgroundColor: "#F6F7FF",
    },
    formControl: drawerCard.formControl,
})

const theme = createTheme({
    typography: MuiTypographyStyle,
    overrides: {
        MuiFormLabel: {
            root: {
                ...MuiFormLabelRootStyle,
                '&.Mui-bot': {
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#0F172A",
                    lineHeight: "24px",
                },
            }
        },
        MuiInputBase: MuiInputBaseRootStyle,
        MuiOutlinedInput: {
            root: MuiOutlinedInputRootStyle,
            notchedOutline: {
                borderColor: "#CBD5E1",
                borderWidth: 1,
            },
            input: {
                padding: 0,
            },
        },
    }
})


export default function Dropdown({ 
    onClose,
    onDrawerClose, 
    onSave, 
    loading, 
    title = "", 
    message = "", 
    options = [], 
    fieldName = "", 
    id,
    botContext 
}: DropdownProps) {
    const classes = useStyles()
    const [maxOptionError, setMaxOptionError] = useState<string | null>(null)
    const { values, getFieldProps, handleChange, handleSubmit, errors, touched, setFieldValue } = useFormik({
        initialValues: { title, message, saveIntoBotContext: !!fieldName, fieldName, options: options.join("\n") },
        onSubmit: (values) => {
            const newOptions = values.options.split("\n")
            if(newOptions.length > 50) {
                setMaxOptionError("You already added the maximum number of options")
                return;
            }
            const hasInvalidOption = newOptions.some(option => option.trim() === "")
            if(hasInvalidOption) {
                setMaxOptionError("Please add valid option")
                return;
            }
            onSave({id,...values, options: newOptions})
        },
        validationSchema: schema.dropdownValidationSchema(botContext.filter(ctxName => ctxName !== fieldName))
    })

    const saveIntoContextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(event)
        if(!event.target.checked) {
            setFieldValue("fieldName", "")
        }
    }

    const optionsLength = values.options.split("\n").filter(option => option.trim() !== "").length

    const messageError = !!errors.message && !!touched.message
    const titleError = !!errors.title && !!touched.title
    const optionsError = !!errors.options && !!touched.options
    const fieldNameError = !!errors.fieldName && !!touched.fieldName

    return (
        <ThemeProvider theme={theme} >
            <Box className={classes.container} >
                <CardHeader 
                    data-testid="cardHeader"
                    onClose={onClose}
                    title="Dropdown"
                    id={id}
                />
                <Box className={classes.drawerContent} >
                    <Box className={classes.formControl} >
                        <InputLabel htmlFor="title" >Name*</InputLabel>
                        <OutlinedInput
                            placeholder="Dropdown card"
                            fullWidth
                            id="title"
                            name="title"
                            inputProps={{ ...getFieldProps("title"), maxLength: 50 }}
                            error={titleError}
                            data-test-id="txtTitle"
                        />
                        <div style={{ display: "flex", justifyContent: "space-between", gap: 4 }} >
                            {
                                titleError && <Typography className="error" variant="subtitle2" >{errors.title}</Typography>
                            }
                            <Typography variant="subtitle2" style={{ marginLeft: "auto" }} >{values.title.trim().length}/50</Typography>
                        </div>
                    </Box>
                    <MessageTextArea 
                        getFieldProps={getFieldProps}
                        hasMessageError={messageError}
                        messageErrorText={errors.message}
                        value={values.message}
                        botContext={botContext}
                        setFieldValue={setFieldValue}
                    />
                    <Box className={classes.botContainer} >
                        <Box style={{ display: "flex", gap: 8, alignItems: "center" }} >
                            <Checkbox
                                style={{ padding: 0 }}
                                icon={<UncheckedIcon />}
                                checkedIcon={<CheckedIcon />}
                                id="saveIntoBotContext"
                                name="saveIntoBotContext"
                                checked={values.saveIntoBotContext}
                                onChange={saveIntoContextChange}
                                disableRipple
                            />
                            <InputLabel htmlFor="saveIntoBotContext" className="Mui-bot" >Save in bot context</InputLabel>
                        </Box>
                        {values.saveIntoBotContext && (
                            <Box className={classes.formControl} >
                                <InputLabel htmlFor="fieldName" >Field name*</InputLabel>
                                <OutlinedInput
                                    placeholder="Type here"
                                    fullWidth
                                    id="fieldName"
                                    name="fieldName"
                                    className="secondary"
                                    inputProps={{ ...getFieldProps("fieldName"), maxLength: 50 }}
                                    error={fieldNameError}
                                />
                                <div style={{ display: "flex", justifyContent: fieldNameError ? "space-between" : "flex-end", gap: 4 }} >
                                    {
                                        fieldNameError && <Typography className="error" variant="subtitle2" >{errors.fieldName}</Typography>
                                    }
                                    <Typography variant="subtitle2" >{values.fieldName.trim().length}/50</Typography>
                                </div>
                            </Box>
                        )}
                    </Box>
                    <Box className={classes.formControl} style={{gap: 8}} >
                        <InputLabel htmlFor="options" >{`Option(s) (${optionsLength})*`}</InputLabel>
                        <Typography variant="subtitle2" >Maximum 50 options</Typography>
                        <MaximumOptionError error={maxOptionError} handleClose={() => setMaxOptionError(null)} />
                        <TextareaAutosize
                            id="options"
                            placeholder="Type your options here"
                            className={`${classes.textArea} ${optionsError ? "error" : ""}`}
                            style={{height: 101}}
                            {...getFieldProps("options")}
                        />
                        <Typography variant="subtitle2" className={optionsError ? "error" : ""} >
                            {
                                optionsError ? errors.options : "Separate your options by a new line"
                            }
                        </Typography>
                    </Box>
                </Box>
                <CardFooter 
                    onClose={onDrawerClose}
                    onSaveClick={() => handleSubmit()}
                    loading={loading}
                />
            </Box>
        </ThemeProvider>
    )
}

