import React from "react";
import { makeStyles } from "@material-ui/core";
import { CloseOutlined, InfoOutlined } from "@material-ui/icons";

const useStyles = makeStyles({
    errorContainer: {
        borderLeft: "4px solid #F59E0B",
        padding: "8px 8px 8px 16px",
        backgroundColor: "#FEF3C7",
        display: "flex",
        gap: 8,
        alignItems: "center",
        borderRadius: 4,
    },
    closeButton: {
        all: "unset",
        cursor: "pointer",
        marginLeft: "auto",
    },
    errorText: {
        color: "#F59E0B",
        margin: 0,
        fontFamily: "Cairo",
        fontSize: 12,
        lineHeight: "18px",
        fontWeight: 400,
    },
})

export default function GenericError({error, handleCloseError}: { error: string, handleCloseError: () => void}) {
    const classes = useStyles()

    return (
        <div className={classes.errorContainer} >
            <InfoOutlined htmlColor="#F59E0B" />
            <p className={classes.errorText} >
                {error}
            </p>
            <button aria-label={`Close ${error}`} className={classes.closeButton} onClick={handleCloseError} >
                <CloseOutlined htmlColor="#F59E0B" />
            </button>
        </div>
    )
}
