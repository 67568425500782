import React from "react";

import {
  IconButton,

  // Customizable Area Start
  styled,
  Breadcrumbs,
  Button,
  createTheme,
  ThemeProvider,
  CircularProgress,
  Dialog,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ArrowBackRounded, SettingsRounded, DeleteOutlineRounded, Clear } from "@material-ui/icons";
import ChatbotSidePanel from "./components/ChatbotSidePanel.web";
import Sidebar from "../../../components/src/Sidebar.web";
import Whiteboard from "../../../components/src/Whiteboard.web";
import ChatbotName from "./components/ChatbotName.web";
import {utils} from "./utils.web";
import { ReactFlowProvider } from "reactflow";

const StyledBotHeader = styled("header")({
  display: "flex",
  padding: "24px 48px",
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 2px 8px 0px #00000014",
  justifyContent: "space-between",
})

const BreadCrumbBotContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: 16,
})

const BotEditContainer = styled("div")({
  gap: 8,
  display: "flex",
  alignItems: "center",
  '& .cursor-pointer': {
    cursor: "pointer",
  },
})

const BotSettingsButton = styled("button")({
  all: "unset",
  cursor: "pointer",
  display: "flex",
  gap: 8,
  alignItems: "center",
  fontSize: 16,
  lineHeight: "24px",
  fontFamily: "Cairo",
  fontWeight: 700,
  color: "#0F172A",
})

const OverlaySpinnerContainer = styled("div")({
  width: "100vw",
  height: "100vh",
  position: "fixed",
  inset: 0,
  display: "grid",
  backgroundColor: "rgba(15, 23, 42, 0.4)",
  placeItems: "center",
  zIndex: 2500,
})

const BotActionContainer = styled("div")({
  alignItems: "center",
  display: "flex",
  gap: 32,
})

const WhiteBoardContainer = styled("div")({
  width: "100%",
  height: "calc(100vh - 92px)",
  '&[data-drawer-open="true"]': {
    width: "calc(100% - 440px)"
  },
})

const DialogActionButton = styled("button")({
  all: "unset",
  cursor: "pointer",
  width: "100%",
  backgroundColor: "#DBF0F2",
  padding: "10px 0",
  display: "flex",
  justifyContent: "center",
  borderRadius: 8,
  fontFamily: "Inter",
  fontSize: 16,
  lineHeight: "24px",
  color: "#51ABB3",
  fontWeight: 700,
  '&[aria-disabled="true"]': {
    pointerEvents: "none",
  },
})


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontSize: 16,
      lineHeight: "24px",
      fontFamily: "Cairo",
      color: "#000000",
      fontWeight: 400,
      textAlign: "center",
    },
    h2: {
      fontFamily: "Cairo",
      fontSize: 24,
      lineHeight: "32px",
      color: "#0F172A",
      fontWeight: 700,
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        padding: 32,
        gap: 12,
        alignItems: "center",
      },
      elevation24: {
        boxShadow: "0px 8px 32px 0px #0000000F,0px 4px 8px 0px #00000008,0px 25px 50px 0px #00000017",
      },
      rounded: {
        borderRadius: 8,
      },
    },
    MuiBreadcrumbs: {
      ol: {
        gap: 2,
      },
      separator: {
        marginRight: 0,
        marginLeft: 0,
      },

    },
    MuiButton: {
      root: {
        textTransform: "none",
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: "transparent",
        },
      },
      text: {
        fontSize: 24,
        fontWeight: 400,
        fontFamily: "Cairo",
        letterSpacing: "-0.005em",
        padding: "4px 8px",
        color: "#475569",
        lineHeight: "32px",
        '&.active': {
          color: "#0F172A",
          fontWeight: 700,
        },
      },
      contained: {
        fontFamily: 'Cairo',
        fontWeight: 700,
        boxShadow: "none",
        fontSize: 16,
        backgroundColor: "#E5F6FF",
        lineHeight: "24px",
        color: "#51ABB3",
        padding: "10px 16px",
        borderRadius: 8,
        '&:hover': {
          boxShadow: "none",
          backgroundColor: "#E5F6FF",
          color: "#51ABB3",
        },
        '&.primary': {
          width: 100,
          color: "#FFFFFF",
          backgroundColor: "#51ABB3",
        },
        '&.primary:hover': {
          color: "#FFFFFF",
          backgroundColor: "#51ABB3",
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
        '&.close-dialog': {
          position: "absolute",
          top: 32,
          right: 32,
        },
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: "#FFFFFF",
        height: "auto",
        minHeight: "auto",
        boxSizing: "border-box",
      },
      paperWidthSm: {
        maxWidth: 411,
        width: 411,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(15, 23, 42, 0.4)",
      },
    },
  },
});

// Customizable Area End

import Chatbot5Controller, { Props } from "./Chatbot5Controller";

export default class Chatbot5 extends Chatbot5Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderArrowForward = () => (
    <svg width="24" height="24" id="arrow-forward" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.99953 6.70978C8.60953 7.09978 8.60953 7.72978 8.99953 8.11978L12.8795 11.9998L8.99953 15.8798C8.60953 16.2698 8.60953 16.8998 8.99953 17.2898C9.38953 17.6798 10.0195 17.6798 10.4095 17.2898L14.9995 12.6998C15.3895 12.3098 15.3895 11.6798 14.9995 11.2898L10.4095 6.69978C10.0295 6.31978 9.38953 6.31978 8.99953 6.70978Z" fill="#64748B" />
    </svg>
  )

  renderErrorIcon = () => (
    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="80" height="80" rx="40" fill="#DC2626"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.16602 40.0003C7.16602 21.6003 22.066 6.66699 40.466 6.66699C58.8994 6.66699 73.8327 21.6003 73.8327 40.0003C73.8327 58.4003 58.8994 73.3337 40.466 73.3337C22.066 73.3337 7.16602 58.4003 7.16602 40.0003ZM43.8327 26.667C43.8327 24.8337 42.3327 23.3337 40.4994 23.3337C38.666 23.3337 37.166 24.8337 37.166 26.667V40.0003C37.166 41.8337 38.666 43.3337 40.4994 43.3337C42.3327 43.3337 43.8327 41.8337 43.8327 40.0003V26.667ZM40.4993 66.667C25.766 66.667 13.8327 54.7337 13.8327 40.0003C13.8327 25.267 25.766 13.3337 40.4993 13.3337C55.2327 13.3337 67.166 25.267 67.166 40.0003C67.166 54.7337 55.2327 66.667 40.4993 66.667ZM37.166 50.0003V56.667H43.8327V50.0003H37.166Z" fill="white"/>
    </svg>
  )
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Dialog 
          open={this.state.isAddingCardsFromTemplate} 
          data-testid="overlay-loader"
          PaperProps={{
            style: {
              width: "100vw",
              height: "100vh",
              display: "grid",
              backgroundColor: "rgba(15, 23, 42, 0.4)",
              placeItems: "center",
              margin: 0,
              maxWidth: "100vw",
              maxHeight: "100vh",
              boxShadow: "none",
              padding: 0,
            }
          }}
        >
          <CircularProgress style={{height: 40, width: 40, color: "#FFFFFF"}} />
        </Dialog>
        <Dialog data-testid="delete confirmation popup" open={this.state.openDeleteConfirmDialog} onClose={this.handleCloseConfirmationDialog} >
          <IconButton aria-label="close confirmation delete card popup" className="close-dialog" onClick={this.handleCloseConfirmationDialog} >
            <Clear htmlColor="#334155" />
          </IconButton>
          <DeleteOutlineRounded style={{height: 40, width: 40}} htmlColor="#DC2626" />
          <Typography component="p" variant="h2" >Delete Card</Typography>
          <Typography variant="body1" >
            You are about to delete a card. Are you sure that you want to delete <strong>{this.nodeDeleteTitle}</strong> from this bot?
          </Typography>
          <DialogActionButton data-testid="delete card" aria-disabled={this.state.cardDeleteLoading} onClick={this.handleDeleteCardNode} >
            {this.state.cardDeleteLoading ? (
              <CircularProgress style={{height: 24, width: 24, color: "#51ABB3"}} />
            ) : "Delete"}
          </DialogActionButton>
        </Dialog>
        <Dialog data-testid="chatbot-error-dialog" open={this.state.showChatbotErrorDialog} onClose={this.handleCloseChatbotErrorDialog} >
          <IconButton className="close-dialog" aria-label="close chatbot error dialog" onClick={this.handleCloseChatbotErrorDialog} >
            <Clear htmlColor="#334155" />
          </IconButton>
          {this.renderErrorIcon()}
          <Typography component="p" variant="h2" >Chatbot errors!</Typography>
          <Typography variant="body1" >
            Ensure you resolve the errors before you try to publish the chatbot
          </Typography>
          <DialogActionButton data-testid="close errors dialog" onClick={this.handleCloseChatbotErrorDialog} >
            Go back
          </DialogActionButton>
        </Dialog>
        <ChatbotSidePanel
          sidebarTop={this.sidebarTop}
          data-testid="sidepanel"
          contextVariables={this.state.contextVariables}
          selectedCard={this.state.selectedCard}
          navigation={this.props.navigation}
          isDrawerOpen={this.state.isDrawerOpen}
          onDrawerClose={this.handleDrawerClose}
          handleAddCard={this.handleAddCardData}
          handleConnectionTypeCard={this.handleConnectionTypeCard}
          selectedCardData={ this.state.selectedNodeId ? this.state.selectedNodeData?.[this.state.selectedNodeId] : undefined}
          token={this.state.token}
          source={this.state.source}
          isTemplate={!!this.props.isTemplate}
          handleCloseSelectedCard={this.handleCloseSelectedCard}
          handleOpenSelectedCard={this.handleOpenSelectedCard}
          previousCardId={this.criteriaRoutingId}
          botContext={this.botContext}
          handleAddConnectorCardsFromTemplateToChatbot={this.handleAddConnectorCardsFromTemplateToChatbot}
          targetCards={utils.getTargetCardOptionsForGoToTileCard(this.state.actions, +this.state.source[0])}
          allActionCards={utils.getAllActionCards(this.state.actions)}  
          isUserEdatt={this.state.isUserEdatt}
        />
        <div style={{ display: "flex", backgroundColor: "#FFFFFF" }}>
          <Sidebar navigation={this.props.navigation} activeMenu="bots" />
          <div style={{ width: "100%" }} >
            <StyledBotHeader ref={this.headerRef} data-testid="botHeader" >
              <BreadCrumbBotContainer >
                <IconButton onClick={this.handleNavigateToBotsPage} >
                  <ArrowBackRounded htmlColor="#0F172A" />
                </IconButton>
                <Breadcrumbs data-test-id="breadcrumb" separator={this.renderArrowForward()} >
                  <Button data-testid="bots-page" variant="text" onClick={this.handleNavigateToBotsPage} >Bots</Button>
                  <Button className="active" variant="text">Add bot</Button>
                </Breadcrumbs>
                <ChatbotName 
                  isTemplate={!!this.props.isTemplate} 
                  navigation={this.props.navigation} 
                  handleLogout={this.handleLogout}  
                  handleSetEdatt={this.handleSetEdatt}
                  data-testid="chatbot-name"
                />
              </BreadCrumbBotContainer>
              <BotActionContainer >
                {
                  !this.props.isTemplate && (
                    <BotSettingsButton onClick={this.handleNavigateToBotSettingsPage} >
                      <SettingsRounded htmlColor="#0F172A" />
                      Chatbot settings
                    </BotSettingsButton>
                  )
                }
                <BotEditContainer style={{ gap: 12 }} >
                  {
                    !this.props.isTemplate && (
                      <>
                        <Button variant="contained" >Save draft</Button>
                        <Button data-testid="publish-bot" className="primary" variant="contained" onClick={this.handlePublishBot} >Publish</Button>
                      </>
                    )
                  }
                </BotEditContainer>
              </BotActionContainer>
            </StyledBotHeader>
            <WhiteBoardContainer data-testid="whiteboard" data-drawer-open={this.state.isDrawerOpen} >
              {
                this.state.connectorCardLoading ? (
                  <div style={{width: "100%", height: "100%", display: "grid", placeItems: "center"}} >
                    <CircularProgress data-testid="loader" style={{color: "#51ABB3"}} />
                  </div>
                ) : (
                  <ReactFlowProvider>
                    <Whiteboard 
                      cardData={this.state.actions} 
                      onAddNodeClick={this.handleShowCardsDrawer}
                      onCardNodeClick={this.handleCardNodeClick} 
                      data-testid="interactive-whiteboard"
                      onCardNodeDelete={this.handleOpenConfirmationDialog}
                      checkForError={this.state.hasErrorInTheChatbot}
                      onTemplateCreate={this.onTemplateCreate}
                      isTemplate={!!this.props.isTemplate}
                      handleConnectNode={this.handleConnectNode}
                      templateCreateLoading={this.state.templateCreateLoading}
                      handleDeleteMultipleCards={this.handleDeleteMultipleCards}
                    />
                  </ReactFlowProvider>
                )
              }
            </WhiteBoardContainer>
          </div>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
