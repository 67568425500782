import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  OutlinedInput,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import CardHeader from "../../chatbot5/src/components/CardHeader.web";
import CardFooter from "../../chatbot5/src/components/CardFooter.web";
import {theme} from "../../chatbot5/src/theme.web";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { DragIndicatorOutlined, DeleteOutlineRounded, AddCircleOutlineRounded } from "@material-ui/icons";
import AudienceCriteria from "./components/AudienceCriteria.web";
import { Rule } from "./CfconnectorlogicController";


const RuleContainer = styled("div")({
  display: "flex",
  gap: 8,
  borderRadius: 6,
  padding: 16,
  backgroundColor: "#F6F7FF",
  boxShadow: "0px 2px 8px 0px #00000014",
  alignItems: "flex-start",
  '& .grab': {
    all: "unset",
    cursor: "grab",
  },
  '& .delete-rule': {
    all: "unset",
    cursor: "pointer",
  },
  '& .title': {
    margin: 0,
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "24px",
    color: "#334155",
  },
  '& .flex': {
    display: "flex"
  },
  '& .flex-column': {
    flexDirection: "column"
  },
  '& .gap-2': {
    gap: 2
  },
  '& .gap-4': {
    gap: 4
  },
  '& .gap-8': {
    gap: 8
  },
  '& .items-center': {
    alignItems: "center"
  },
  '& .rule-criteria': {
    fontFamily: "Cairo",
    margin: 0,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "22px",
    color: "#51ABB3",
    gap: "0px 8px",
    flexWrap: "wrap",
    '& > span': {
      color: "#0F172A",
      '&[data-valid="false"]': {
        color: "#F59E0B"
      },
    },
  },
})

const AddRule = styled("button")({
  all: "unset",
  display: "flex",
  alignItems: "center",
  gap: 8,
  fontFamily: "Cairo",
  fontSize: 16,
  lineHeight: "24px",
  fontWeight: 700,
  color: "#64748B",
  cursor: "pointer",
})

const StyledFormControl = styled("div")({
  display: "flex",
  gap: 4,
  flexDirection: "column",
  '& .formErrorOrInfo': {
    display: "flex",
    justifyContent: "space-between",
    gap: 4,
  },
})

const criteriaRoutingTheme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: theme.MuiTypographyStyle,
  overrides: {
    MuiContainer: {
      root: {
        width: 440,
        marginLeft: 0,
        marginRight: 0,
        backgroundColor: "#FFFFFF",
        display: "flex",
        height: "100%",
        overflowY: "auto",
        flexDirection: "column",
        scrollbarWidth: "none",
        paddingRight: 0,
        paddingLeft: 0,
        '&::-webkit-scrollbar': {
          width: 0,
        },
        "@media (min-width: 600px)": {
          paddingLeft: 0,
          paddingRight: 0,
        },
        '& .content': {
          flexGrow: 1,
          backgroundColor: "#FFFFFF",
          display: "flex",
          gap: 12,
          padding: "0px 48px 24px",
          flexDirection: "column",
        },
        '& .form-control': {
          display: "flex",
          gap: 4,
          flexDirection: "column",
          '&.gap-8': {
            gap: 8,
          },
          '&.items-start': {
            alignItems: "flex-start",
          },
        },
      },
    },
    MuiOutlinedInput: theme.MuiOutlinedInputStyle,
    MuiInputBase: theme.MuiInputBaseRootStyle,
    MuiFormLabel: {
      root: theme.MuiFormLabelRootStyle
    },
  },
});

export const operatingSystemsObj: {[OperatingKey: string]: string} = {
  "android": "Android",
  "blackberry": "Blackberry",
  "iOS": "iOS",
  "iPadOS": "iPadOS",
  "linux": "Linux",
  "windows": "Windows",
  "nokia": "Nokia"
}

export const comparatorsObj: {[ComparatorKey: string]: string} = {
  "is_equal_to": "is",
  "is_not_equal_to": "is not",
  "contains": "contains",
  "between": "between",
  "after": "after",
  "before": "before"
}
// Customizable Area End

import CfconnectorlogicController, {
  Props,
  configJSON,
} from "./CfconnectorlogicController";

export default class Cfconnectorlogic extends CfconnectorlogicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderOperatingSystem = (operatingSystemsValue: string[], comparator: string, criteriaIndex: number) => {
    const newValues = operatingSystemsValue.map((systemVal) => operatingSystemsObj[systemVal])
    return (
      <p className="flex gap-8 rule-criteria" key={criteriaIndex} >
        Operating system
        <span>{`${comparatorsObj[comparator]} ${newValues.join(", ")}`}</span>
      </p>
    )
  }

  renderCurrentPageURL = (currentPageURL: string, comparator: string, criteriaIndex: number) => {
    return (
      <p className="flex gap-8 rule-criteria" key={criteriaIndex} >
        Current page URL
        <span>{`${comparatorsObj[comparator]} ${currentPageURL}`}</span>
      </p>
    )
  }

  renderTimeOfTheDay = (time: string[], comparator: string, criteriaIndex: number) => {
    return (
      <p className="flex gap-8 rule-criteria" key={criteriaIndex} >
        Time of the day
        <span>{`${comparatorsObj[comparator]} ${time.join(" and ")}`}</span>
      </p>
    )
  }

  renderContextVariable = (options: string[], subField: string, comparator: string, criteriaIndex: number) => {
    const doesSubFieldExist = this.props.contextVariables.subFields.includes(subField)
    return (
      <p className="flex gap-8 rule-criteria" key={criteriaIndex} >
        {`Context variable ${subField}`}
        <span>{comparatorsObj[comparator]}</span>
        <span data-testid={subField} data-valid={doesSubFieldExist} >{doesSubFieldExist ? options.join(", ") : `{variable deleted}`}</span>
      </p>
    )
  }

  renderNameError = () => {
    return this.state.nameError ? <Typography variant="subtitle2" className="error">Please add a name</Typography> : null
  }

  renderRuleCriteria = (rule: Rule) => {
    return rule.criteria.map((criteria, criteriaIndex) => {
      if(criteria.fieldName === "operating_system" && criteria.selectedOperatingSystems) {
        return this.renderOperatingSystem(criteria.selectedOperatingSystems, criteria.comparator, criteriaIndex)
      }
      if(criteria.fieldName === "current_page_url" && criteria.currentPageURL) {
        return this.renderCurrentPageURL(criteria.currentPageURL, criteria.comparator, criteriaIndex)
      }
      if(criteria.fieldName === "time_of_the_day" && criteria.time) {
        return this.renderTimeOfTheDay(criteria.time, criteria.comparator, criteriaIndex)
      }
      if(criteria.fieldName === "bot_contexts" && criteria.subField && criteria.selectedSubFieldOptions) {
        return this.renderContextVariable(criteria.selectedSubFieldOptions, criteria.subField, criteria.comparator, criteriaIndex)
      }
    })
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={criteriaRoutingTheme}>
        <Container data-testid="container" >
            <CardHeader id={this.props.cardId} onClose={this.props.onClose} title="Criteria routing" />
            <div className="content" >
                <StyledFormControl>
                  <InputLabel htmlFor="criteriaRoutingName" >Name*</InputLabel>
                  <OutlinedInput 
                    id="criteriaRoutingName"
                    name="criteriaRoutingName"
                    inputProps={{maxLength: 50, placeholder: "Criteria routing card"}}
                    value={this.state.nameValue}
                    error={this.state.nameError}
                    onChange={this.handleChangeNameInput}
                  />
                  <div className="formErrorOrInfo" >
                    {this.renderNameError()}
                    <Typography variant="subtitle2" className="ml-auto" >{this.state.nameValue.trim().length}/50</Typography>
                  </div>
                </StyledFormControl>
                {
                  this.state.isAudienceCriteria ? (
                    <AudienceCriteria 
                      onAddRule={this.onAddRule}
                      initialCriteria={this.state.selectedCriteria} 
                      contextVariables={this.props.contextVariables}
                      onDeleteCriteria={this.onDeleteCriteria}
                    />
                  ) : (
                    <div className="form-control gap-8 items-start" >
                      <Typography variant="body1" >{`Rules - (${this.state.rules.length})*`}</Typography>
                      <DragDropContext onDragEnd={this.onDragEnd} >
                        <Droppable droppableId="criteria-rules" >
                          {(droppableProvided) => (
                            <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps} style={{width: "100%"}}  >
                              <div className="form-control gap-8" >
                                {
                                  this.state.rules.map((rule, ruleIndex) => (
                                    <Draggable draggableId={`${rule.id}`} key={rule.id} index={ruleIndex} >
                                      {(draggableProvided) => (
                                        <RuleContainer ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} >
                                          <button {...draggableProvided.dragHandleProps} className="grab" >
                                            <DragIndicatorOutlined htmlColor="#64748B" />
                                          </button>
                                          <div className="flex flex-column gap-4" style={{cursor: "pointer"}} onClick={this.onRuleAndCriteriaClick(ruleIndex)} >
                                            <div className="flex gap-2 items-center" >
                                              <p className="title" >{rule.title}</p>
                                              <button className="delete-rule" aria-label={`delete ${rule.title}`} onClick={this.onDeleteRule(rule.id, rule.ruleId)} >
                                                <DeleteOutlineRounded htmlColor="#0F172A"style={{height: 18, width: 18}} />
                                              </button>
                                            </div>
                                            {this.renderRuleCriteria(rule)}
                                          </div>
                                        </RuleContainer>
                                      )}
                                    </Draggable>
                                  ))
                                }
                              </div>
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                      <AddRule onClick={this.handleAddRule} >
                        <AddCircleOutlineRounded style={{height: 20, width: 20}} htmlColor="#64748B" />
                        Add rule
                      </AddRule>
                    </div>
                  )
                }
            </div>
            <CardFooter 
              onSaveClick={this.onSave} 
              onClose={this.props.onDrawerClose} 
              loading={this.props.loading} 
            />
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
