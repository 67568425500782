import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import {
    InputLabel,
    OutlinedInput,
    ThemeProvider,
    Typography,
    createTheme,
    makeStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Avatar,
} from "@material-ui/core";
import { Clear, ExpandMore, AddCircleOutlineRounded } from "@material-ui/icons";
import { useFormik } from "formik";
import {
    theme as cardTheme
} from "../theme.web";
import ImageCropDialog from "../../../../components/src/ImageCropDialog.web";
import ImageFileInput from "../../../../components/src/ImageFileInput.web";
import {utils} from "../utils.web";
import CardHeader from "./CardHeader.web";
import MessageTextArea from "./MessageTextArea.web";
import CardFooter from "./CardFooter.web";
import { NodeData } from "../../../../components/src/CardNode.web";

const {
    MuiTypographyStyle,
    MuiFormLabelRootStyle,
    MuiInputBaseRootStyle,
    MuiOutlinedInputStyle,
    drawerCard,
} = cardTheme

type SelectedLink = Pick<Link, "name" | "linkType" | "openLink" | "iconFile" | "url" | "icon" | "id"> & {destination?: string}

export type ShareLinkFormValues = {
    name: string,
    message: string;
    links: Array<SelectedLink>;
    id?: number;
}

export type ShareLinkProps = {
    onClose: () => void;
    onSave: (values: ShareLinkFormValues) => void;
    loading: boolean;
    id?: number;
    title?: string;
    message?: string;
    initialLinks?: NodeData["links"];
    onDrawerClose: () => void;
    botContext: string[];
}

export type Link = {
    linkId: string;
    title: string;
    name: string;
    linkType: string;
    openLink: string;
    icon?: string | null;
    iconFile?: File | null;
    url?: string;
    nameError?: boolean;
    id?: number;
    isExpanded: boolean;
    email?: string;
    phone?: string;
    linkTypeInputError?: boolean;
}

type LinkTypeInput = Extract<keyof Link, "url" | "email" | "phone">

export const url_regex = /^https?:\/\/(?:[\w-]+\.)+[a-z]{2,}(?:\/[a-zA-Z0-9-_.~%]*)*(?:\?[a-zA-Z0-9-_.~%=&,]*)?(?:#[\w-]*)?$/


const useStyles = makeStyles({
    shareLinkContainer: drawerCard.container,
    drawerContent: drawerCard.drawerContent,
    formControl: {
        ...drawerCard.formControl,
        '& .relative': {
            position: "relative",
        },
        '&.flex-grow-1': {
            flexGrow: 1
        },
    },
    formErrorOrInfo: {
        display: "flex",
        justifyContent: "space-between",
        gap: 4,
        '&.error': {
            color: "#D97706",
        },
    },
    radioContainer: {
        display: "flex",
        gap: 24,
    },
    iconOrURLContainer: {
        display: "flex",
        gap: 16,
    },
    uploadFileBtn: {
        all: "unset",
        height: 56,
        width: 56,
        border: "1px dashed #CBD5E1",
        borderRadius: 8,
        backgroundColor: "#FFFFFF",
        display: "grid",
        placeItems: "center",
        cursor: "pointer",
    },
    addLinkBtn: drawerCard.addLinkOrOptionButton,
    removeLinkIcon: {
        all: "unset",
        cursor: "pointer",
        position: "absolute",
        top: -10,
        right: -10,
        zIndex: 1,
        height: 20,
        width: 20,
        display: 'grid',
        placeItems: "center",
        backgroundColor: "#51ABB3",
        borderRadius: 60,
    },
})

const theme = createTheme({
    typography: {
        ...MuiTypographyStyle,
        body1: {
            fontFamily: "Cairo",
            fontSize: 16,
            lineHeight: "24px",
            fontWeight: 400,
            color: "#0F172A",
        },
    },
    overrides: {
        MuiFormLabel: {
            root: {
                ...MuiFormLabelRootStyle,
            }
        },
        MuiOutlinedInput: MuiOutlinedInputStyle,
        MuiInputBase: MuiInputBaseRootStyle,
        MuiIconButton: {
            root: {
                padding: 0,
            },
            edgeEnd: {
                marginRight: 0,
            },
        },
        MuiPaper: {
            elevation1: {
                boxShadow: "none",
            },
        },
        MuiAccordion: {
            root: {
                padding: 0,
                backgroundColor: "#F6F7FF",
                '&::before': {
                    height: 0,
                },
                '&.Mui-expanded': {
                    margin: 0,
                },
            },
            rounded: {
                borderRadius: 8,
                '&:last-child': {
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                },
            },
        },
        MuiAccordionSummary: {
            root: {
                padding: "16px 8px 16px 16px",
                minHeight: "auto",
                '&.Mui-expanded': {
                    minHeight: "auto",
                },
            },
            content: {
                margin: 0,
                fontSize: 16,
                fontWeight: 400,
                lineHeight: "24px",
                fontFamily: "Cairo",
                color: "#000000",
                '&.Mui-expanded': {
                    margin: "0",
                },
            },
        },
        MuiAccordionDetails: {
            root: {
                padding: "8px 8px 16px 16px",
                flexDirection: "column",
                gap: 24,
            },
        },
        MuiFormGroup: {
            root: {
                gap: 8,
                marginTop: 8,
            },
        },
        MuiFormControlLabel: {
            root: {
                marginLeft: 0,
                marginRight: 0,
                gap: 8,
            },
        },
        MuiRadio: {
            root: {
                padding: 0,
            },
        },
        MuiAvatar: {
            root: {
                height: 56,
                width: 56,
                borderRadius: 8,
            },
        },
    }
})

export const shareLinkValidationSchema = yup.object().shape({
    name: yup.string().trim().required("Please add a name"),
    message: yup.string().trim().required("Please add a message"),
})

const getLinkTypeInputValue = (link: Link) => {
    if(link.linkType === "regular") {
        return link.url;
    }
    if(link.linkType === "phone") {
        return link.phone
    }
    return link.email
}

const getLinkInputError = (link: Link) => {
    if(link.linkType === "regular") {
        return !linkTypeRegex.url.test(link.url!)
    }

    if(link.linkType === "phone") {
        return !linkTypeRegex.phone.test(link.phone!)
    }

    return !linkTypeRegex.email.test(link.email!)
}

const getValidSaveLinks = (link: Link) => {
    const newLink: SelectedLink = {
        name: link.name.trim(),
        linkType: link.linkType,
        openLink: link.openLink,
        iconFile: link.iconFile,
    }

    if(link.linkType === "regular") {
        newLink["url"] = link.url?.trim()
    } else if(link.linkType === "phone") {
        newLink["destination"] = link.phone
    } else if(link.linkType === "mailTo") {
        newLink["destination"] = link.email
    }

    if(link.id) {
        newLink["id"] = link.id
    }

    if(link.iconFile) {
        newLink.iconFile = link.iconFile
    }

    if(link.icon && !link.iconFile) {
        newLink.icon = link.icon
    }

    return newLink
}

export const linkTypeRegex: Record<LinkTypeInput, RegExp> = {
    url: url_regex,
    email: /^[\w-.]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,4}$/,
    phone: /^\d{10,15}$/
} 


export default function ShareLink({ 
    onClose, 
    onSave, 
    loading, 
    id, 
    title="",
    message="",
    onDrawerClose,
    initialLinks,
    botContext 
}: ShareLinkProps) {
    const linkWithExpandedStatusRef = useRef<Map<string, boolean>>(new Map())
    const [links, setLinks] = useState<Link[]>(() => utils.getTransformedLinks(linkWithExpandedStatusRef.current,initialLinks))
    const [selectedLinkIconId, setSelectedLinkIconId] = useState<string | null>(null)
    const { handleSubmit, values, errors, getFieldProps, touched, setFieldValue } = useFormik({
        initialValues: {
            name: title,
            message: message,
        },
        validationSchema: shareLinkValidationSchema,
        onSubmit: (values) => {
            const hasError = utils.checkLinkNameAndURLError(links)
            if(hasError) {
                const newLinks = links.map((link) => {
                    const hasLinkTypeInputError = getLinkInputError(link);

                    return {
                        ...link,
                        nameError: link.name.trim().length === 0,
                        linkTypeInputError: hasLinkTypeInputError,
                        isExpanded: link.name.trim().length === 0 || hasLinkTypeInputError,
                    }
                })
                setLinks(newLinks)
                return;
            }
            const saveLinks = links.map(link => {
                const newLink = getValidSaveLinks(link)
                linkWithExpandedStatusRef.current.set(link.name, link.isExpanded)
                return newLink
            })
            onSave({...values, links: saveLinks, id})
        }
    })
    
    const classes = useStyles()
    const fileInputRef = useRef<Array<HTMLInputElement | null>>([null])
    const fileRef = useRef<string>("")

    useEffect(() => {
        setLinks(utils.getTransformedLinks(linkWithExpandedStatusRef.current,initialLinks))
    }, [initialLinks])

    const updateLinks = (link: Partial<Link> & { linkId: string }) => {
        const newLinks = [...links]
        const linkIndex = newLinks.findIndex((item) => item.linkId === link.linkId)
        newLinks[linkIndex] = { ...newLinks[linkIndex], ...link }
        setLinks(newLinks)
    }

    const handleLinkNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const linkId = name.split("-")[0]
        updateLinks({ linkId, name: value, nameError: value.trim().length === 0 })
    }

    const handleLinkInputChange = (linkTypeInput: LinkTypeInput) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const linkId = name.split("-")[0]
        updateLinks({ linkId, [linkTypeInput]: value, linkTypeInputError: !linkTypeRegex[linkTypeInput].test(value) })
    }

    const handleLinkTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const linkId = name.split("-")[0]
        updateLinks({ linkId, linkType: value })
    }

    const handleOpenLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const linkId = name.split("-")[0]
        updateLinks({ linkId, openLink: value })
    }

    const handleAddMoreLink = () => {
        const newLink: Link = {
            linkId: `link${links.length + 1}`,
            title: `Link ${links.length + 1}`,
            name: `Link ${links.length + 1}`,
            linkType: "regular",
            openLink: "_blank",
            url: "https://",
            isExpanded: true,
            email: "",
            phone: "",
        }
        fileInputRef.current.push(null)
        setLinks([...links, newLink])
    }

    const openFilePicker = (index: number) => {
        fileInputRef.current[index]?.click()
    }

    const onImageLoad = (name: string, imageUrl: string) => {
        const id = name.split("-")[0]
        fileRef.current = imageUrl
        setSelectedLinkIconId(id)
    }

    const handleRemoveLinkIcon = (linkId: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
        updateLinks({linkId: linkId, icon: null, iconFile: null})
    }

    const onApply = (image: { url: string, file: File }) => {
        if(!selectedLinkIconId) {
            return;
        }
        updateLinks({linkId: selectedLinkIconId, icon: image.url, iconFile: image.file})
        fileRef.current = ""
        setSelectedLinkIconId(null)
    }

    const onExpandLink = (linkIndex: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        const newLinks = [...links]
        newLinks[linkIndex] = {
            ...newLinks[linkIndex],
            isExpanded
        }
        setLinks(newLinks)
    }

    const linkInputTitleAndPlaceholder: Record<string, {placeholder: string, label: string, error: string, id: string, onChange: (event: React.ChangeEvent<HTMLInputElement>) => void}> = {
        regular: {
            placeholder: "https://example.com",
            label: "URL*",
            error: "Please add a valid URL",
            id: "url",
            onChange: handleLinkInputChange("url")
        },
        phone: {
            placeholder: "Tel: 000 000 00",
            label: "Destination*",
            error: "Please add a valid phone number",
            id: "phone",
            onChange: handleLinkInputChange("phone")
        },
        mailTo: {
            placeholder: "Mailto:",
            label: "Destination*",
            error: "Please add a valid email",
            id: "email",
            onChange: handleLinkInputChange("email")
        },
    }

    return (
        <ThemeProvider theme={theme} >
            <ImageCropDialog
                data-test-id="image-crop-dialog"
                key={selectedLinkIconId ? "image-crop-dialog" : "dialog"} 
                open={!!selectedLinkIconId}
                handleClose={() => setSelectedLinkIconId(null)}
                selectedImage={fileRef.current}
                onApply={onApply}
            />
            <div className={classes.shareLinkContainer}>
                <CardHeader onClose={onClose} data-testid="cardHeader" id={id} title="Share link(s)" />
                <div className={classes.drawerContent}>
                    <div className={classes.formControl} >
                        <InputLabel htmlFor="title" >Name*</InputLabel>
                        <OutlinedInput
                            placeholder="Send link(s) card"
                            fullWidth
                            id="name"
                            name="name"
                            value={values.name}
                            inputProps={{ ...getFieldProps("name"), maxLength: 50 }}
                            error={!!errors.name && touched.name}
                            data-test-id="name"
                        />
                        <div className={classes.formErrorOrInfo}>
                            {
                                errors.name && touched.name && <Typography data-test-id="nameError" className="error" variant="subtitle2" >{errors.name}</Typography>
                            }
                            <Typography style={{ marginLeft: "auto" }} variant="subtitle2" >{values.name.trim().length}/50</Typography>
                        </div>
                    </div>
                    <MessageTextArea 
                        getFieldProps={getFieldProps}
                        hasMessageError={!!errors.message && !!touched.message}
                        messageErrorText={errors.message}
                        value={values.message}
                        botContext={botContext}
                        setFieldValue={setFieldValue}
                    />
                    <div className={classes.formControl}>
                        <InputLabel id="link-property" >{`Link property (${links.length})*`}</InputLabel>
                        {
                            links.map((link, index) => (
                                <Accordion data-testid={`link-accordian-${index+1}`} expanded={link.isExpanded} onChange={onExpandLink(index)} key={link.linkId} >
                                    <AccordionSummary data-testid={`link-accordian-header-${index+1}`} disableRipple expandIcon={<ExpandMore htmlColor="#64748B" />} id={link.linkId + "-header"} >
                                        {link.name}
                                    </AccordionSummary>
                                    <AccordionDetails data-testid={`link-accordian-details-${index+1}`} >
                                        <div className={classes.formControl} >
                                            <InputLabel htmlFor={link.linkId} >Name*</InputLabel>
                                            <OutlinedInput
                                                placeholder={`Link ${index + 1}`}
                                                fullWidth
                                                id={`${link.linkId}-name`}
                                                name={`${link.linkId}-name`}
                                                value={link.name}
                                                error={link.nameError}
                                                onChange={handleLinkNameChange}
                                            />
                                            {
                                                link.nameError && (
                                                    <Typography variant="subtitle2" className="error" >Please add a name</Typography>
                                                )
                                            }
                                        </div>
                                        <div className={classes.radioContainer}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Link Type</FormLabel>
                                                <RadioGroup
                                                    aria-label="link type"
                                                    name={`${link.linkId}-linkType`}
                                                    value={link.linkType}
                                                    onChange={handleLinkTypeChange}
                                                >
                                                    <FormControlLabel
                                                        value="regular"
                                                        control={<Radio data-testid={`link-${index+1}-regular`} aria-checked={link.linkType === "regular"} icon={<RadioUncheckedIcon />} checkedIcon={<RadioCheckedIcon />} />}
                                                        label="Regular"
                                                    />
                                                    <FormControlLabel
                                                        value="phone"
                                                        control={<Radio data-testid={`link-${index+1}-phone`} aria-checked={link.linkType === "phone"} icon={<RadioUncheckedIcon />} checkedIcon={<RadioCheckedIcon />} />}
                                                        label="Phone"
                                                    />
                                                    <FormControlLabel
                                                        value="mailTo"
                                                        control={<Radio data-testid={`link-${index+1}-mailTo`} aria-checked={link.linkType === "mailTo"} icon={<RadioUncheckedIcon />} checkedIcon={<RadioCheckedIcon />} />}
                                                        label="Mailto"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Open Links</FormLabel>
                                                <RadioGroup
                                                    aria-label="open links"
                                                    name={`${link.linkId}-openLink`}
                                                    value={link.openLink}
                                                    onChange={handleOpenLinkChange}
                                                >
                                                    <FormControlLabel
                                                        value="_blank"
                                                        control={<Radio data-testid={`link-${index+1}-blank`} aria-checked={link.openLink === "_blank"} icon={<RadioUncheckedIcon />} checkedIcon={<RadioCheckedIcon />} />}
                                                        label="In new page"
                                                    />
                                                    <FormControlLabel
                                                        value="_self"
                                                        control={<Radio data-testid={`link-${index+1}-self`} aria-checked={link.openLink === "_self"} icon={<RadioUncheckedIcon />} checkedIcon={<RadioCheckedIcon />} />}
                                                        label="In the same page"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                        <div className={classes.iconOrURLContainer}>
                                            <div className={classes.formControl}>
                                                <InputLabel>Icon</InputLabel>
                                                {
                                                    link.icon ? (
                                                        <div className="relative">
                                                            <Avatar data-test-id="icon-avatar" src={link.icon} />
                                                            <button aria-label={`remove ${link.linkId} icon`} onClick={handleRemoveLinkIcon(link.linkId)} className={classes.removeLinkIcon} >
                                                                <Clear htmlColor="#FFFFFF" fontSize="small" style={{height: 14, width: 14}} />
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <ImageFileInput data-test-id="image-file-input" ref={(elem) => fileInputRef.current[index] = elem } onImageLoad={onImageLoad} name={`${link.linkId}-file`} />
                                                            <button aria-label="open file browser" onClick={() => openFilePicker(index)} className={classes.uploadFileBtn} >
                                                                <svg fill="#64748B" width="24px" height="24px" viewBox="-3.2 -3.2 38.40 38.40" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#334155" transform="rotate(0)">
                                                                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.8960000000000001"></g>
                                                                    <g id="SVGRepo_iconCarrier">
                                                                        <path d="M23.845 8.124c-1.395-3.701-4.392-6.045-8.921-6.045-5.762 0-9.793 4.279-10.14 9.86-2.778 0.889-4.784 3.723-4.784 6.933 0 3.93 3.089 7.249 6.744 7.249h2.889c0.552 0 1-0.448 1-1s-0.448-1-1-1h-2.889c-2.572 0-4.776-2.404-4.776-5.249 0-2.514 1.763-4.783 3.974-5.163l0.907-0.156-0.080-0.916-0.008-0.011c0-4.871 3.205-8.545 8.161-8.545 3.972 0 6.204 1.957 7.236 5.295l0.214 0.688 0.721 0.015c3.715 0.078 6.972 3.092 6.972 6.837 0 3.408-2.259 7.206-5.678 7.206h-2.285c-0.552 0-1 0.448-1 1s0.448 1 1 1l2.277-0.003c5-0.132 7.605-4.908 7.605-9.203 0-4.616-3.617-8.305-8.14-8.791zM16.75 16.092c-0.006-0.006-0.008-0.011-0.011-0.016l-0.253-0.264c-0.139-0.146-0.323-0.219-0.508-0.218-0.184-0.002-0.368 0.072-0.509 0.218l-0.253 0.264c-0.005 0.005-0.006 0.011-0.011 0.016l-3.61 3.992c-0.28 0.292-0.28 0.764 0 1.058l0.252 0.171c0.28 0.292 0.732 0.197 1.011-0.095l2.128-2.373v10.076c0 0.552 0.448 1 1 1s1-0.448 1-1v-10.066l2.199 2.426c0.279 0.292 0.732 0.387 1.011 0.095l0.252-0.171c0.279-0.293 0.279-0.765 0-1.058z"></path>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </>
                                                    )
                                                }
                                            </div>
                                            <div className={`${classes.formControl} flex-grow-1`} >
                                                <InputLabel htmlFor={`${link.linkId}-${linkInputTitleAndPlaceholder[link.linkType].id}`} >
                                                    {linkInputTitleAndPlaceholder[link.linkType].label}
                                                </InputLabel>
                                                <OutlinedInput
                                                    key={link.linkType}
                                                    placeholder={linkInputTitleAndPlaceholder[link.linkType].placeholder}
                                                    fullWidth
                                                    id={`${link.linkId}-${linkInputTitleAndPlaceholder[link.linkType].id}`}
                                                    name={`${link.linkId}-${linkInputTitleAndPlaceholder[link.linkType].id}`}
                                                    value={getLinkTypeInputValue(link)}
                                                    onChange={linkInputTitleAndPlaceholder[link.linkType].onChange}
                                                    error={link.linkTypeInputError}
                                                />
                                                {
                                                    link.linkTypeInputError && (
                                                        <Typography variant="subtitle2" className="error" >{linkInputTitleAndPlaceholder[link.linkType].error}</Typography>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            ))
                        }
                    </div>
                    <button id="add-link" onClick={handleAddMoreLink} className={classes.addLinkBtn}>
                        <AddCircleOutlineRounded htmlColor="#64748B" style={{height: 20, width: 20}} />
                        Add link
                    </button>
                </div>
                <CardFooter onClose={onDrawerClose} loading={loading} onSaveClick={() => handleSubmit()} />
            </div>
        </ThemeProvider>
    )
}

function RadioUncheckedIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#64748B"/>
        </svg>
    )
}

function RadioCheckedIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill="#51ABB3"/>
            <circle cx="10" cy="10" r="4" fill="white"/>
        </svg>
    )
}
