import { FormQuestion } from "./AutomaticFormCreationController.web"


const getFormQuestionsWithError = (formQuestions: FormQuestion[]) => {
    let hasQuestionError = false;
    let hasOptionError = false;
    const newFormQuestionsWithError = formQuestions.map(field => {
        const newField: FormQuestion = {...field, questionError: undefined}

        newField["questionError"] = ""

        if(field.question.trim().length === 0) {
            newField["questionError"] = "Please add a question"
            hasQuestionError = true
        }

        if(field.options) {
            newField["optionError"] = ""
            field.options.forEach(option => {
                if(option.value.trim().length === 0) {
                    newField["optionError"] = "Please fill all options"
                    hasOptionError = true
                }
            })
        }


        return newField
    })

    return {
        hasQuestionError,
        hasOptionError,
        newFormQuestionsWithError,
    }
}

export const utils = {
    getFormQuestionsWithError,
}
