import React, { useRef, useState } from "react";
import {
    theme as cardTheme,
} from "../theme.web";
import {
    InputLabel,
    TextareaAutosize,
    Typography,
    makeStyles,
} from "@material-ui/core";

import { FieldInputProps, FormikErrors, FormikValues } from "formik";

const {
    drawerCard,
} = cardTheme

const useMessageStyle = makeStyles({
    formControl: drawerCard.formControl,
    relative: {
        position: "relative",
    },
    textArea: drawerCard.textArea,
    formErrorOrInfo: drawerCard.formErrorOrInfo,
    description: {
        margin: 0,
        fontWeight: 400,
        fontSize: 12,
        lineHeight: "18px",
        color: "#94A3B8",
        fontFamily: "Cairo",
        '&[data-error="true"]': {
            color: "#D97706"
        },
    },
    label: {
        fontFamily: "Cairo",
        lineHeight: "22px",
        fontSize: 14,
        color: "#475569",
        fontWeight: 700,
    },
    botContextListContainer: {
        display: "flex",
        flexDirection: "column",
        listStyle: "none",
        margin: 0,
        padding: 0,
        maxHeight: 270,
        overflowY: "auto",
        scrollbarWidth: "thin",
        scrollbarColor: "#FFFFFF #1E293B",
        zIndex: 1992,
    },
    botContextListItem: {
        margin: 0,
        padding: "12px 16px",
        boxSizing: "border-box",
        fontFamily: "Cairo",
        fontSize: 14,
        lineHeight: "16px",
        color: "#FFFFFF",
        cursor: "pointer",
        '&:first-child': {
            borderRadius: "8px 8px 0 0",
        },
        '&:last-child': {
            borderRadius: "0 0 8px 8px",
        },
    },
    botContextPopover: {
        position: "absolute",
        top: -15,
        left: "25%",
        overflow: "hidden",
        maxHeight: "none",
        backgroundColor: "#1E293B",
        boxShadow: "0px 2px 4px 0px #00000026",
        boxSizing: "border-box",
        borderRadius: 8,
        width: 180,
        zIndex: 2000,
    },
    botContextBackdrop: {
        position: "fixed",
        inset: 0,
        width: "100vw",
        height: "100vh",
        background: "transparent",
        zIndex: 1800,
    },
})

type MessageTextAreaProps<T extends FormikValues> = {
    getFieldProps: (name: string) => FieldInputProps<string>;
    messageErrorText: string | undefined;
    hasMessageError: boolean;
    value: string;
    placeholder?: string;
    botContext: string[];
    description?: string;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<T>> | Promise<void> | void;
}


export default function MessageTextArea<T extends FormikValues>({
    getFieldProps,
    messageErrorText,
    hasMessageError,
    value,
    placeholder = "Type your message here",
    botContext,
    setFieldValue,
    description,
}: MessageTextAreaProps<T>) {
    const classes = useMessageStyle()
    const textAreaRef = useRef<HTMLTextAreaElement>(null)
    const [open, setOpen] = useState<boolean>(false)
    const isContextSelected = useRef<boolean>(false)
    const isMessageEdited = useRef<boolean>(false)

    const onMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const targetValue = event.target.value
        getFieldProps("message").onChange(event)
        isContextSelected.current = false
        isMessageEdited.current = true
        if (targetValue.endsWith("%") && botContext.length > 0) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }

    const onMessageFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
        const targetValue = event.target.value
        if(!isMessageEdited.current) {
            return;
        }
        if (targetValue.endsWith("%") && !isContextSelected.current) {
            setOpen(true)
        }
    }

    const onBotContextItemClick = (variable: string) => {
        isContextSelected.current = true
        setFieldValue("message", `${value}${variable}%`)
        setOpen(false)
        if(textAreaRef.current) {
            textAreaRef.current.focus()
        }
    }

    return (
        <div className={`${classes.formControl} ${classes.relative}`}>
            <label htmlFor="message" className={classes.label} >Message*</label>
            {description && <p data-testid="description" className={classes.description} >{description}</p>}
            <TextareaAutosize
                id="message"
                placeholder={placeholder}
                className={`${classes.textArea} ${hasMessageError ? "error" : ""}`}
                {...getFieldProps("message")}
                maxLength={1000}
                style={{ minHeight: 101 }}
                ref={textAreaRef}
                onChange={onMessageChange}
                onFocus={onMessageFocus}
            />
            <div className={classes.formErrorOrInfo} >
                <p className={classes.description} data-error={hasMessageError} >
                    {
                        hasMessageError ? messageErrorText : "Type % for dynamic text suggestions"
                    }
                </p>
                <p className={classes.description} >{value.trim().length}/1000</p>
            </div>
            {
                open && (
                    <>
                        <div data-testid="context-backdrop" className={classes.botContextBackdrop} onClick={() => setOpen(false)} />
                        <div data-testid="bot-context-popover" className={classes.botContextPopover} >
                            <ul className={classes.botContextListContainer} >
                                {
                                    botContext.map((value) => (
                                        <li key={value} onClick={() => onBotContextItemClick(value)} className={classes.botContextListItem} >{value}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    </>
                )
            }
        </div>
    )
}
