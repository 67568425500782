import React from "react";

import {
  Box,
  Button,
  Typography,
  IconButton,
  // Customizable Area Start
  Grid,
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import CustomAppHeader from "../../../components/src/CustomAppHeader.web";
import Sidebar from "../../../components/src/Sidebar.web";

import { theme as styledTheme } from "./theme.web";
const { Column, StyledRow } = styledTheme
import Cards from "./components/Cards";
import BarChart from "./components/BarChart";
import LineChart from "./components/LineChart";
import Dougnut from "./components/Dougnut";
import HalfDougnut from "./components/HalfDougnut";

import { Chart as ChartJS, registerables } from "chart.js";
registerables?.forEach(registerable => ChartJS.register(registerable));

// Customizable Area End

import Dashboard4Controller, {
  Props,
  configJSON,
} from "./Dashboard4Controller";

export default class DashboardWeb4 extends Dashboard4Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <DashboardContent>
        <Sidebar navigation={this.props.navigation} activeMenu="dashboard" />
        <Column>
          <CustomAppHeader title={"Home"} navigation={this.props.navigation} showSearchBox={true} />
          <div className="rightside-content">
            <Column style={{ gap: 24 }}>
              <Cards />
              <div className="chat-activity-box">
                <Typography style={{ color: "#64748B" }} component={"h1"}>Chat Activity</Typography>
                <Grid container spacing={3} className="dashboard-chart-box">
                  <Grid item xs={9}>
                    <BarChart />
                  </Grid>
                  <Grid item xs={3}>
                    <LineChart />
                  </Grid>
                </Grid>
              </div>
              <Column className="mode-box">
                <div className="dashboard-chart-box" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "12px" }}>
                  <div className="dougnut-box-1" style={{ width: "496px", height: "241px", boxShadow: "0px 2px 8px 0px #00000014", borderRadius: "8px" }} >
                    <Dougnut />
                  </div>
                  <div className="dougnut-box-2" style={{ width: "704px", height: "241px", boxShadow: "0px 2px 8px 0px #00000014", overflow: "hidden", borderRadius: "8px" }}>
                    <HalfDougnut />
                  </div>
                </div>
              </Column>
            </Column>
          </div>
        </Column>
      </DashboardContent>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const DashboardContent = styled("section")({
  display: "flex",
  alignItems: "stretch",
  justifyContent: "stretch",

  "& .rightside-content": {
    maxWidth: "100%",
    padding: "12px 48px",
    backgroundColor: "#FAFAFA"
  },

  "& .dashboard-chart-box": {
    height: "100%"
  },
  "& .dougnut-box-1": {
    "@media(max-width:1400px)": {
      width: "40% !important"
    },
    "@media(max-width:1300px)": {
      width: "100% !important",
    }
  },
  "& .dougnut-box-2": {
    "@media(max-width:1400px)": {
      width: "60% !important",
  },
  "@media(max-width:1300px)": {
    width: "100% !important",
  }
  },

  "& .chat-activity-box": {
    padding: "24px",
    height: "100%",
    boxShadow: "0px 2px 8px 0px #00000014",
    borderRadius: 8,
  },

  "& .chat-activity-box h1": {
    margin: "0 0 28px 0",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "22px",
    fontFamily: "Cairo",
  },
});
// Customizable Area End
