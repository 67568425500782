import React from "react";
import { ThemeProvider, createTheme, Box, Typography, InputLabel, OutlinedInput, Checkbox, makeStyles } from "@material-ui/core";
import { Formik } from "formik";
import { 
    theme as cardTheme 
} from "../theme.web";
import UncheckedIcon from "./UncheckedIcon.web";
import CheckedIcon from "./CheckedIcon.web";
import CardHeader from "./CardHeader.web";
import MessageTextArea from "./MessageTextArea.web";
import CardFooter from "./CardFooter.web";
import { schema } from "./schema"

const {
    MuiFormLabelRootStyle, 
    MuiTypographyStyle, 
    MuiOutlinedInputRootStyle,
    MuiInputBaseRootStyle,
    drawerCard,
} = cardTheme

export type SliderScaleForm = {
    title: string;
    message: string;
    saveIntoBotContext?: boolean;
    fieldName: string;
    minValue: string;
    maxValue: string;
    id?: number;
}
export type SliderScaleProps = {
    onClose: () => void;
    onSubmit: (values: SliderScaleForm) => void;
    loading: boolean;
    title?: string;
    message?: string;
    fieldName?: string;
    minValue?: string;
    maxValue?: string;
    id?: number;
    onDrawerClose: () => void;
    botContext: string[];
}

const useStyles = makeStyles({
    container: drawerCard.container,
    drawerContent: drawerCard.drawerContent,
})

const theme = createTheme({
    typography: {
        ...MuiTypographyStyle,
    },
    overrides: {
        MuiFormLabel: {
            root: {
                ...MuiFormLabelRootStyle,
                '&.Mui-bot': {
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "24px",
                    color: "#0F172A",
                },
            }
        },
        MuiOutlinedInput: {
            root: {
                ...MuiOutlinedInputRootStyle,
            },
            notchedOutline: {
                borderWidth: 1,
                borderColor: "#CBD5E1",
            },
            input: {
                padding: 0,
            },
        },
        MuiInputBase: {
            ...MuiInputBaseRootStyle,
        },
    }
})

const hasError = (error: string | undefined, touched: boolean | undefined) => !!error && !!touched

export default function SliderScale({
    onClose, 
    onSubmit, 
    loading,
    title="",
    message="",
    fieldName="",
    minValue="1",
    maxValue="10",
    id,
    onDrawerClose,
    botContext
}: SliderScaleProps) {
    const classes = useStyles()
    return (
        <ThemeProvider theme={theme} >
            <Box className={classes.container} >
                <CardHeader id={id} onClose={onClose} title="Slider scale" />
                <Formik
                    initialValues={{ title, message, saveIntoBotContext: !!fieldName, fieldName, minValue, maxValue }}
                    onSubmit={(values) => onSubmit({id, ...values})}
                    validationSchema={schema.sliderScaleValidationSchema(botContext.filter(ctxName => ctxName !== fieldName))}
                    data-test-id="formik"
                >
                    {
                        ({ values, getFieldProps, handleChange, handleSubmit, errors, touched, setFieldValue }) => (
                            <>
                                <Box className={classes.drawerContent} >
                                    <Box style={sliderStyle.formControlStyle} >
                                        <InputLabel htmlFor="title" >Name*</InputLabel>
                                        <OutlinedInput
                                            placeholder="Slider scale field card"
                                            fullWidth
                                            id="title"
                                            name="title"
                                            inputProps={{...getFieldProps("title"),maxLength: 50}}
                                            error={hasError(errors.title , touched.title)}
                                            data-test-id="txtTitle"
                                        />
                                        <div style={{ display: "flex", justifyContent: "space-between", gap: 4 }} >
                                            {
                                                hasError(errors.title , touched.title) && <Typography data-test-id="titleError" className="error" variant="subtitle2" >{errors.title}</Typography>
                                            }
                                            <Typography variant="subtitle2" style={{marginLeft: "auto"}} >{values.title.trim().length}/50</Typography>
                                        </div>
                                    </Box>
                                    <MessageTextArea 
                                        getFieldProps={getFieldProps}
                                        botContext={botContext}
                                        hasMessageError={hasError(errors.message, touched.message)}
                                        messageErrorText={errors.message}
                                        value={values.message}
                                        setFieldValue={setFieldValue} 
                                    />
                                    <Box style={sliderStyle.botContainer} >
                                        <Box style={{ display: "flex", gap: 8, alignItems: "center" }} >
                                            <Checkbox
                                                style={{ padding: 0 }}
                                                icon={<UncheckedIcon />}
                                                checkedIcon={<CheckedIcon />}
                                                id="saveIntoBotContext"
                                                name="saveIntoBotContext"
                                                checked={values.saveIntoBotContext}
                                                onChange={(event) => {
                                                    handleChange(event)
                                                    if(!event.target.checked) {
                                                        setFieldValue("fieldName", "")
                                                    }
                                                }}
                                                data-test-id="saveIntoBotCheckbox"
                                                disableRipple
                                            />
                                            <InputLabel htmlFor="saveIntoBotContext" className="Mui-bot" >Save in bot context</InputLabel>
                                        </Box>
                                        {values.saveIntoBotContext && (
                                            <Box style={sliderStyle.formControlStyle} >
                                                <InputLabel htmlFor="fieldName" >Field name*</InputLabel>
                                                <OutlinedInput
                                                    placeholder="Type here"
                                                    fullWidth
                                                    id="fieldName"
                                                    name="fieldName"
                                                    className="secondary"
                                                    inputProps={{...getFieldProps("fieldName"), maxLength: 50}}
                                                    error={hasError(errors.fieldName , touched.fieldName)}
                                                    data-test-id="txtFieldName"
                                                />
                                                <div style={{ display: "flex", justifyContent: hasError(errors.fieldName,touched.fieldName) ? "space-between" : "flex-end", gap: 4 }} >
                                                    {
                                                        hasError(errors.fieldName,touched.fieldName) && <Typography data-test-id="fieldNameError" className="error" variant="subtitle2" >{errors.fieldName}</Typography>
                                                    }
                                                    <Typography variant="subtitle2" >{values.fieldName.trim().length}/50</Typography>
                                                </div>
                                            </Box>
                                        )}
                                    </Box>
                                    <Box style={{ display: "flex", gap: 16 }} >
                                        <Box style={sliderStyle.formControlStyle} >
                                            <InputLabel htmlFor="minValue" >Minimum Selection*</InputLabel>
                                            <OutlinedInput
                                                type="number"
                                                fullWidth
                                                id="minValue"
                                                name="minValue"
                                                inputProps={{...getFieldProps("minValue"), min: "0"}}
                                                error={!!errors.minValue}
                                                data-test-id="txtMinValue"
                                            />
                                            {errors.minValue && <Typography data-test-id="minValueError" className="error" variant="subtitle2" >{errors.minValue}</Typography>}
                                        </Box>
                                        <Box style={sliderStyle.formControlStyle} >
                                            <InputLabel htmlFor="maxValue" >Maximum Selection*</InputLabel>
                                            <OutlinedInput
                                                type="number"
                                                fullWidth
                                                id="maxValue"
                                                name="maxValue"
                                                inputProps={{...getFieldProps("maxValue"), min: "0"}}
                                                error={!!errors.maxValue}
                                                data-test-id="txtMaxValue"
                                            />
                                            {errors.maxValue && <Typography data-test-id="maxValueError" className="error" variant="subtitle2" >{errors.maxValue}</Typography>}
                                        </Box>
                                    </Box>
                                </Box>
                                <CardFooter loading={loading} onClose={onDrawerClose} onSaveClick={() => handleSubmit()} />
                            </>
                        )
                    }
                </Formik>
            </Box>
        </ThemeProvider>
    )
}

const sliderStyle: Record<string, React.CSSProperties> = {
    formControlStyle: {
        display: "flex",
        flexDirection: "column",
        gap: 4,
    },
    textArea: {
        minHeight: 101,
    },
    errorTextArea: {
        border: "1px solid #F59E0B"
    },
    botContainer: {
        padding: "16px 8px 16px 16px",
        display: "flex",
        flexDirection: "column",
        gap: 12,
        borderRadius: 8,
        backgroundColor: "#F6F7FF",
    },
}
