// Customizable Area Start
import React from "react";
import EndUserChatBotController, { Props } from "./EndUserChatBotController";
import { Button, createTheme, styled, ThemeProvider } from "@material-ui/core";
import CustomChat from "../src/components/CustomChat.web"

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontFamily: "Cairo",
      fontSize: 24,
      lineHeight: "32px",
      color: "#0F172A",
      fontWeight: 700,
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        padding: 32,
        gap: 12,
        alignItems: "center",
      },
      elevation24: {
        boxShadow: "0px 8px 32px 0px #0000000F,0px 4px 8px 0px #00000008,0px 25px 50px 0px #00000017",
      },
      rounded: {
        borderRadius: 8,
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: "transparent",
        },
      },
      text: {
        fontSize: 24,
        fontWeight: 400,
        fontFamily: "Cairo",
        letterSpacing: "-0.005em",
        padding: "4px 8px",
        color: "#475569",
        lineHeight: "32px",
        '&.active': {
          color: "#0F172A",
          fontWeight: 700,
        },
      },
      contained: {
        fontFamily: 'Cairo',
        fontWeight: 700,
        boxShadow: "none",
        fontSize: 16,
        backgroundColor: "#E5F6FF",
        lineHeight: "24px",
        color: "#51ABB3",
        padding: "10px 16px",
        borderRadius: 8,
        '&:hover': {
          boxShadow: "none",
          backgroundColor: "#E5F6FF",
          color: "#51ABB3",
        },
        '&.primary': {
          width: 100,
          color: "#FFFFFF",
          backgroundColor: "#51ABB3",
        },
        '&.primary:hover': {
          color: "#FFFFFF",
          backgroundColor: "#51ABB3",
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
        '&.close-dialog': {
          position: "absolute",
          top: 32,
          right: 32,
        },
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: "#FFFFFF",
        height: "auto",
        minHeight: "auto",
        boxSizing: "border-box",
      },
      paperWidthSm: {
        maxWidth: 411,
        width: 411,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(15, 23, 42, 0.4)",
      },
    },
  },
});

export default class EndUserChatBot extends EndUserChatBotController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <CustomWeb className={`${this.state.isChatOpen ? "judhjui" : ""}`}>
          {this.state.initialOpen && <CustomChat isOpen={this.state.isChatOpen} />}
          <CustomButton onClick={this.setIsChatOpen} flag={this.state.isChatOpen} className="toggle">
            <div className={`${this.state.isChatOpen ? "cross-fade-in" : "cross-fade-out"} cross-transform`}>
              <div style={{ transform: "translate(-50%, -50%)", position: "absolute", top: "50%", left: "50%" }}>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.7615 6.20874C18.5747 6.02148 18.321 5.91625 18.0565 5.91625C17.792 5.91625 17.5384 6.02148 17.3515 6.20874L12.4615 11.0887L7.57152 6.19874C7.38469 6.01148 7.13104 5.90625 6.86652 5.90625C6.602 5.90625 6.34835 6.01148 6.16152 6.19874C5.77152 6.58874 5.77152 7.21874 6.16152 7.60874L11.0515 12.4987L6.16152 17.3887C5.77152 17.7787 5.77152 18.4087 6.16152 18.7987C6.55152 19.1887 7.18152 19.1887 7.57152 18.7987L12.4615 13.9087L17.3515 18.7987C17.7415 19.1887 18.3715 19.1887 18.7615 18.7987C19.1515 18.4087 19.1515 17.7787 18.7615 17.3887L13.8715 12.4987L18.7615 7.60874C19.1415 7.22874 19.1415 6.58874 18.7615 6.20874Z" fill="white" />
                </svg>
              </div>
            </div>
            <div className={`${this.state.isChatOpen ? "chat-fade-out" : "chat-fade-in"} chat-transform`}>
              <div style={{ transform: "translate(-50%, -50%)", position: "absolute", top: "50%", left: "50%" }}>
                <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.48017 22.9225C7.08241 22.9225 6.749 22.7879 6.47993 22.5189C6.21086 22.2498 6.07632 21.9164 6.07632 21.5186V18.7109H24.3263V6.07632H27.134C27.5318 6.07632 27.8652 6.21086 28.1343 6.47993C28.4033 6.749 28.5379 7.08241 28.5379 7.48017V28.5379L22.9225 22.9225H7.48017ZM0.460938 21.5186V1.86478C0.460938 1.46703 0.595473 1.13361 0.864543 0.864543C1.13361 0.595473 1.46703 0.460938 1.86478 0.460938H20.1148C20.5125 0.460938 20.846 0.595473 21.115 0.864543C21.3841 1.13361 21.5186 1.46703 21.5186 1.86478V14.4994C21.5186 14.8972 21.3841 15.2306 21.115 15.4996C20.846 15.7687 20.5125 15.9032 20.1148 15.9032H6.07632L0.460938 21.5186ZM18.7109 13.0956V3.26863H3.26863V13.0956H18.7109Z" fill="white" />
                </svg>
              </div>
            </div>
          </CustomButton>
        </CustomWeb>
      </ThemeProvider>
    );
  }
}
const CustomWeb = styled("main")({
  position: "fixed",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  right: "2%",
  bottom: "2%",
  height: "100%",
  justifyContent: "flex-end",
  // overflow: "hidden",

  "& .chat-header-nav": {
    display: "flex",
    alignItems: "center",
    background: "#51ABB3",
    borderRadius: "20px 20px 0px 0px",
    padding: "24px",
    gap: "12px",
    position: "relative"
  },
  "& .chat-header-nav .chatbot-avatar": {
    width: "60px",
    height: "60px",
    background: "#E2E8F0",
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
  },
  "& .chat-header-nav h1": {
    width: "245px",
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: "20px",
    color: "fff",
    textAlign: "start"
  },
  "& .chat-header-nav h1 span": {
    fontWeight: 700,
    display: "block"
  },
  "& .chat-header-nav div": {
    display: "flex",
    alignItems: "center",
  },
  "& .chat-header-nav .chatbot-accessibility": {
    position: "absolute",
    right: 0,
    cursor: "pointer"
  },
  "@media(max-width:767px)": {
    position: "static",

    "& .chat-header-nav": {
      borderRadius: 0
    },
    "&.judhjui .toggle": {
      display: "none"
    }
  }
})
const CustomButton = styled(Button)(({ flag }: { flag: boolean }) => ({
  borderRadius: "100%",
  background: "#51ABB3",
  width: "70px",
  height: "70px",
  position: "relative",

  "&:hover": {
    background: "#51ABB3",
    boxShadow: "0 1px 5px 0 rgba(0,0,0,.2)"
  },
  "& .cross-fade-in": {
    transition: "transform 0.10s linear 0s, opacity 0.06s linear 0s",
    opacity: 1,
    transform: "rotate(0deg) scale(1)",
  },
  "& .cross-fade-out": {
    transition: "transform 0.10s linear 0s, opacity 0.06s linear 0s",
    opacity: 0,
    transform: "rotate(25deg) scale(0.5) !important",
  },
  "& .chat-fade-in": {
    transition: "transform 0.10s linear 0s, opacity 0.06s linear 0s",
    opacity: 1,
    transform: "rotate(0deg) scale(1)",
  },
  "& .chat-fade-out": {
    transition: "transform 0.10s linear 0s, opacity 0.06s linear 0s",
    opacity: 0,
    transform: "rotate(-25deg) scale(0.5) !important",
  },

  "@media(max-width:767px)": {
    display: flag ? "none" : "block",
  },
}));

// Customizable Area End
