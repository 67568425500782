import React from "react";
// Customizable Area Start
import PostCreationController, {Props} from "./PostCreationController.web";
import { Button, Typography, styled, Box, Dialog } from "@material-ui/core";
import Sidebar from "../../../components/src/Sidebar.web";
import CustomMDEditor from "../../../components/src/CustomMDEditor.web";
import CloseIcon from '@material-ui/icons/Close';
import TrainingHubSettings from "../../../components/src/TrainingHubSettings.web";

// Customizable Area End

export default class PostCreation extends PostCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { options } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <PostCreationBox>
          <Sidebar navigation={this.props.navigation} />
          <div className="rightside-blog-content">
          <div style={{ padding: "24px 48px", display: "flex", gap: 12, alignItems: "center", justifyContent:"space-between", borderBottom:"1px solid #E2E8F0",background:'#fff', position: "sticky", top: 0, zIndex: 2 }} >
              <Typography
                variant="h1"
                style={{
                  fontWeight: 700,
                  fontSize: 18,
                  lineHeight: "26px",
                  color: '#0F172A',
                  fontFamily: "Cairo"
                }}
              >{this.state.postId ? "Edit User Guides" : "User Guides"}
              </Typography>
              <div>
                <button data-test-id="save-changes" className="saveBtn" onClick={this.saveUserProfileInfo}>Save</button>
                <Button data-test-id="cancelChanges" onClick={this.onFormCloseHandler}><CloseIcon /></Button>
              </div>
            </div>
            <div className="postContent-inner">
              <div className="leftcontent">
                <div className="leftcontent-inner">
                  <Typography variant="h4" style={{fontFamily: "Cairo"}}>User Guides Details</Typography>
                  <Box className="form-controls">
                    <Typography style={{fontFamily: "Cairo"}}>Title*</Typography>
                    <input
                      type="text"
                      value={this.state.postTitle}
                      onChange={this.handleTitle}
                      placeholder="Type the title here"
                      className={this.state.errors.postTitle ? 'titlerror' : ''}
                      id="postTitle"
                      maxLength={100}
                    />
                  </Box>
                  <Box>
                    <Typography style={{
                      margin: '20px 0', fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: 700,
                      marginBottom: "10px",
                      color: '#64748B',
                      fontFamily: "Cairo"
                    }}>Content*</Typography>
                    <CustomMDEditor
                      value={this.state.editorValue}
                      onChange={this.handleEditorChange}
                      className={this.state.errors.editorValue ? 'contenterror' : ''}
                      hasRightAlign={this.state.hasRightAlign}
                    />
                  </Box>
                </div>

              </div>
              <TrainingHubSettings 
                data-testid="settings"
                handleLogout={this.handleLogout}
                tagType="user_guide"
                selectedPractices={this.state.selectedPracticeValue}
                showPracticeError={this.state.showPracticeError}
                practiceError={this.state.selectPracticeError}
                handleClosePracticeError={this.handleCloseError("showPracticeError")}
                handlePracticeAssociationApply={this.handleChangeSelectedPractieValue}
                handleGlobalSwitchChange={this.handleGolbalSwitchChange}
                isGlobal={this.state.isGlobal}
                selectedTags={this.state.selectedTags}
                handleTagsApply={this.handleApplyTags}
              />
            </div>
          </div>
        </PostCreationBox>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const PostCreationBox = styled('div')({
  display: "flex",
  alignItems: "stretch",
  justifyContent: "stretch",
  background: '#fafafa',

  "& .contenterror":{
    border: "1px solid #F59E0B !important",
    background: "#FEF3C7 "
  },
  "& .contenterror .ql-toolbar":{
    borderBottom: "1px solid #F59E0B !important",
  },
  "& .rightside-blog-content":{
    width: "calc(100% - 100px)",

  },
  "& .postContent-inner":{
    display: "flex",
    alignItems: "stretch",
    justifyContent: "stretch",
    width:"100%",
    minHeight: 'calc(100% - 93px)',

  },
  "& .leftcontent":{
    width: "calc(100% - 400px)",
    padding: "24px 48px",
    background:"#fafafa",
  },
  "& .rightsidebar":{
    width: "400px",
    boxShadow: "0px 24px 20px 4px rgba(0,0,0,0.1)",
    padding: "24px 48px",
    borderLeft: "1px solid rgb(226, 232, 240)",
    background:'#fff',
  },
  "& .manageBtn":{
    color: "#51ABB3",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    background: "transparent",
    fontWeight: 700,
    lineHeight: "20px",
    paddingBottom: "0",
    paddingTop: "0",
    display: "inline-block"
  },
  "& .leftcontent-inner":{
    background:'#fff',
    width:'100%',
    padding: "25px",
    boxSizing:"border-box",
  },
  "& .leftcontent-inner h4":{
    fontSize: "20px",
    lineHeight: "28px",
    color: "#0F172A",
    fontWeight: 700,
    marginBottom:20,
  },
  "& .form-controls":{
    marginBottom:'10px',
  },
  "& .form-controls p":{
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 700,
    marginBottom: "10px",
    color: '#64748B',
  },
  "& .form-controls input[type='text']":{
    width: "100%",
    border: "1px solid #CBD5E1",
    height: "44px",
    padding: "10px 15px",
    borderRadius: "8px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#0F172A",
    fontWeight: 400,

    "&:focus":{outline:'none'},
    "&.titlerror":{
      background:'#FEF3C7',
      borderColor:'#D97706',
      color:'#D97706'
    }
  },

  "& .form-controls .MuiSwitch-root":{
    width: "48px",
    height: "28px",
    padding: "0",
    borderRadius: "40px"
  },
  "& .form-controls .MuiButtonBase-root":{
    padding: "2px"
  },
  "& .form-controls .MuiSwitch-track":{
    backgroundColor:'#E2E8F0',
    opacity:1,
  },
  "& .form-controls .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":{
    backgroundColor:'#51ABB3',
    opacity:1,
  },
  "& .form-controls .MuiSwitch-thumb":{
    width: "24px",
    height: "24px"
  },
  "& .saveBtn": {
    color: "#fff",
    border: "1px solid #E5F6FF",
    cursor: "pointer",
    margin: "0 5px",
    display: "inline-block",
    padding: "10px",
    fontSize: "16px",
    minWidth: "90px",
    background: "#51ABB3",
    textAlign: "center",
    fontWeight: 700,
    lineHeight: "24px",
    borderRadius: "8px",
    textTransform: "capitalize"
  },
  "& .Practicerror": {
    display: "flex",
    alignContent: "space-around",
    alignItems: "center",
    background: "#FEF3C7",
    height: "40px",
    borderLeft: "4px solid #F59E0B",
    borderRadius: "4px 0 0px 4px",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 400,
    color: "#F59E0B",
    padding: "0 10px",
    marginBottom: "10px",
    gap: "10px"
  }

});
const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    width: "669px",
    height: "569px",
    borderRadius: "24px"
  },
  "& .customModalHeader": {
    padding: "24px 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #CBD5E1"
  },
  "& .customModalHeader h4": {
    fontSize: "24px",
    lineHeight: "32px",
    color: "#0F172A",
    fontWeight: 700
  },
  "& .customModalbody":{
    padding: "24px 40px",
    maxHeight: "379px",
    height: "100%",
    overflowY:'auto',
  },
  "& .customModalFooter":{
    padding: "24px 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderTop: "1px solid #CBD5E1"
  },
  "& .modalBtn": {
    background: "#E5F6FF",
    border: "1px solid #E5F6FF",
    padding: "16px",
    display: "inline-block",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#51ABB3",
    fontWeight: 700,
    textTransform: "capitalize",
    minWidth: "112px",
    textAlign: "center",
    margin: "0 5px"
  }
})
// Customizable Area End