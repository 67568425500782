// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import DocumentPicker, {
  DocumentPickerResponse,
} from "react-native-document-picker";
import { getStorageData, removeStorageData, isEmail } from "../../../framework/src/Utilities";
import { IImageUrl, IPresignUrl, ImageUrl, OrganisationData, TagsType } from "./types";
import moment from "moment";
import { Platform } from "react-native";
import { Practice } from "../../../blocks/automaticformcreation/src/AutomaticFormCreationController.web";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  token: string;
  loading: boolean;
  currentDate: string;
  isDisabled: boolean;
  projectId: string;
  projectDataId: string;
  formValues: {
    projectName: string;
    projectDescription: string;
    clientDetails: string;
    teamSize: string;
    projectUrl: string;
    developerEmail: string;
    developerFullName: string;
    phoneNumber: string;
    startDate: Date;
    endDate: Date;
    // web events
    projectImages: IPresignUrl[];
    projectDocuments: IPresignUrl[];
    projectImagesUrl: {
      name: string;
      imageUrl?: ImageUrl;
      imageObj: Blob;
      id?: number | null;
    }[];
    projectDocumentsUrl: {
      name: string;
      imageUrl?: ImageUrl;
      imageObj: Blob;
      id?: number | null;
    }[];
  };
  imageUrl: IImageUrl[];
  documentUrl: IImageUrl[];
  images: IPresignUrl[];
  documents: IPresignUrl[];

  signPostingId: string;
  organisation: OrganisationData;
  selectedPracticeValue: number[];
  isGlobal: boolean;
  practices: Practice[];
  saveFormLoading: boolean;
  showOptionError: boolean;
  showPracticeError: boolean;
  openBulkModal: boolean;
  tags: any;
  opentagModal: boolean;
  AllTagsData: any;
  newTagsArray: TagsType[];
  tagsId: number[];
  selectPracticeError?: string;
  selectedTag: number[];
  fileData: any;
  fileName: string | null;
  fileUrl: string | null;
  showError: boolean;
  formError: {
    projectNameError: string;
    projectDescriptionError: string;
    clientDetailsError: string;
    teamSizeError: string;
    projectUrlError: string;
    developerEmailError: string;
    developerFullNameError: string;
    phoneNumberError: string;
    startDateError: string;
    endDateError: string;
  };
  errors: {
    practiceValue: string;
    tagName: string;
    fileError: string;

    formDataError: string | undefined;
  }
}

interface SS {
  id: any;
}

export default class CreateEditProjectPortfolioController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiCreateProjectCallId: string = "";

  apiGetSignPostingId: string = "";
  apiGetPracticeId: string = "";
  getAllTagsApiCallID: string = "";
  postTagsApiCallID: string = "";
  getSubmitpostDetailCallID: string = "";
  getCSVFileCallId: string = "";
  updateTagsApiCallID: string = "";
  deleteTagsApiCallID: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      token: "",
      loading: false,
      currentDate: "",
      isDisabled: false,
      projectId: "",
      projectDataId: "",
      formValues: {
        projectName: "",
        projectDescription: "",
        clientDetails: "",
        teamSize: "",
        projectUrl: "",
        startDate: new Date(),
        endDate: new Date(),
        developerEmail: "",
        developerFullName: "",
        phoneNumber: "",

        // web events
        projectImages: [],
        projectDocuments: [],
        projectImagesUrl: [],
        projectDocumentsUrl: [],
      },
      images: [],
      documents: [],
      imageUrl: [],
      documentUrl: [],

      signPostingId: "",
      tags: [],
      organisation: {
        type_id: "",
        organisation_name: "",
        organisation_code: "",
        service_type: "",
        access_route: "",
        website_url: "",
        contact_number: "",
        street: "",
        town: "",
        postcode: "",
        country: "",
        criteria: "",
        opening_times: ""
      },
      showError: false,
      fileName: null,
      selectedPracticeValue: [],
      selectedTag: [],
      fileData: null,
      isGlobal: false,
      practices: [],
      saveFormLoading: false,
      showOptionError: false,
      showPracticeError: false,
      openBulkModal: false,
      AllTagsData: [],
      newTagsArray: [],
      tagsId: [],
      opentagModal: false,
      fileUrl: null,
      formError: {
        projectNameError: "",
        projectDescriptionError: "",
        clientDetailsError: "",
        teamSizeError: "",
        projectUrlError: "",
        developerEmailError: "",
        developerFullNameError: "",
        phoneNumberError: "",
        startDateError: "",
        endDateError: "",
      },
      errors: {
        formDataError: undefined,
        practiceValue: "",
        tagName: "",
        fileError: ""
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog(from, message)

    this.handleResponseForgetSignPosting(message)

    this.handleResponseForSavingPostData(message)
    this.handleResponseForSavingCSVData(message)
  }

  async componentDidMount() {
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    const signPostingId = this.props.navigation.getParam("navigationBarTitleText")
    this.setState({ token: token, signPostingId: signPostingId });
    let date = new Date();
    this.setState({ currentDate: date.toISOString().split("T")[0] });

    if (signPostingId) {
      this.fetchSignPostingData()
    }
  }

  createProjectHandleResponse = (responseJson: {
    data: number;
    message: string;
    error: string;
    errors: string[];
  }) => {
    if (responseJson.message || (this.state.projectId && responseJson.data)) {
      let imagesAndDocs: IPresignUrl[] = [];
      let imageUrlsAndDocUrls: (IImageUrl | {
        name: string;
        imageUrl?: ImageUrl;
        imageObj: Blob;
        id?: number | null;
      })[] = [];
      if (Platform.OS == "web") {
        imagesAndDocs = [
          ...this.state.formValues.projectImages,
          ...this.state.formValues.projectDocuments,
        ];
        imageUrlsAndDocUrls = [
          ...this.state.formValues.projectImagesUrl,
          ...this.state.formValues.projectDocumentsUrl,
        ];
      } else {
        imagesAndDocs = [
          ...this.state.images,
          ...this.state.documents,
        ];
        imageUrlsAndDocUrls = [
          ...this.state.imageUrl,
          ...this.state.documentUrl,
        ];
      }
      if (!imagesAndDocs.filter((element) => !element.id).length) {
        this.showAlert("Success", "Updated Successfully!");
        this.setState({ loading: false });
        return false;
      }
      this.setState({ loading: false });
    } else if (responseJson.error || responseJson.errors) {
      this.setState({ loading: false, isDisabled: false });
      this.showAlert(
        "Error",
        responseJson.error || responseJson.errors.toString()
      );
    }
  };

  handleTitle(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, projectName: text },
      formError: { ...this.state.formError, projectNameError: "" },
    });
  }
  handleProjectDescription(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, projectDescription: text },
      formError: { ...this.state.formError, projectDescriptionError: "" },
    });
  }
  handleClientDetails(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, clientDetails: text },
      formError: { ...this.state.formError, clientDetailsError: "" },
    });
  }
  handleTeamSize(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, teamSize: text },
      formError: { ...this.state.formError, teamSizeError: "" },
    });
  }
  handleProjecturl(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, projectUrl: text },
      formError: { ...this.state.formError, projectUrlError: "" },
    });
  }

  handleStartDate(date: Date) {
    this.setState({
      formValues: { ...this.state.formValues, startDate: date },
    });
  }
  handleEndDate(date: Date) {
    this.setState({ formValues: { ...this.state.formValues, endDate: date } });
  }
  handleEmail(text: string) {
    let regex = isEmail("email", text);
    if (regex.status === false) {
      this.setState({
        formValues: { ...this.state.formValues, developerEmail: text },
        formError: {
          ...this.state.formError,
          developerEmailError: "Please enter valid email",
        },
      });
      return false;
    } else {
      this.setState({
        formValues: { ...this.state.formValues, developerEmail: text },
        formError: { ...this.state.formError, developerEmailError: "" },
      });
      return true;
    }
  }
  handleFullName(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, developerFullName: text },
      formError: { ...this.state.formError, developerFullNameError: "" },
    });
  }
  handlePhoneNumber(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, phoneNumber: text },
      formError: { ...this.state.formError, phoneNumberError: "" },
    });
  }
  imagePicker = async () => {
    try {
      const imageResults = await DocumentPicker.pickMultiple({
        type: [DocumentPicker.types.images],
      });
      let imgData: IPresignUrl[] = this.state.images;
      let newImageUrls: IImageUrl[] = this.state.imageUrl;

      imageResults.forEach((item: DocumentPickerResponse) => {
        imgData.push({
          file_name: item.name,
          file_size: item.size ? item.size : 0,
          category: "image",
        });
      });
      imageResults.forEach((item: DocumentPickerResponse) => {
        newImageUrls.push({
          name: item.name,
          type: item.type,
          uri: item.uri,
        });
      });
      this.setState({ images: imgData, imageUrl: newImageUrls });
    } catch (error) { }
  };
  docPicker = async () => {
    try {
      const documentResults = await DocumentPicker.pickMultiple({
        type: [DocumentPicker.types.allFiles],
      });
      let documentData: IPresignUrl[] = this.state.documents;
      let newDocumentUrls: IImageUrl[] = this.state.documentUrl;
      documentResults.forEach((item: DocumentPickerResponse) => {
        documentData.push({
          file_name: item.name,
          file_size: item.size ? item.size : 0,
          category: "document",
        });
      });
      documentResults.forEach((item: DocumentPickerResponse) => {
        newDocumentUrls.push({
          name: item.name,
          type: item.type,
          uri: item.uri,
        });
      });
      this.setState({ documents: documentData, documentUrl: newDocumentUrls });
    } catch (error) { }
  };

  submitData = async () => {
    if (this.state.formValues.projectName == "")
      this.setState({
        formError: {
          ...this.state.formError,
          projectNameError: "Please enter project name ",
        },
      });
    if (this.state.formValues.projectDescription == "")
      this.setState({
        formError: {
          ...this.state.formError,
          projectDescriptionError: "Please Enter Project Description",
        },
      });
    if (this.state.formValues.clientDetails == "")
      this.setState({
        formError: {
          ...this.state.formError,
          clientDetailsError: "Please Enter Client Details ",
        },
      });
    if (this.state.formValues.teamSize == "")
      this.setState({
        formError: {
          ...this.state.formError,
          teamSizeError: "Please Enter Team Size",
        },
      });
    if (this.state.formValues.projectUrl == "")
      this.setState({
        formError: {
          ...this.state.formError,
          projectUrlError: "Please Enter Project Cose",
        },
      });
    if (this.state.formValues.developerFullName == "")
      this.setState({
        formError: {
          ...this.state.formError,
          developerFullNameError: "Please Enter Full Name",
        },
      });
    if (this.state.formValues.phoneNumber == "")
      this.setState({
        formError: {
          ...this.state.formError,
          phoneNumberError: "Please Enter Phone Number",
        },
      });
    if (this.state.formValues.developerEmail == "")
      this.setState({
        formError: {
          ...this.state.formError,
          developerEmailError: "Please Enter Email",
        },
      });
    this.createProjectPortfolioAPI();
  };

  removeImage = (itemImage: IImageUrl) => {
    let finalData = this.state.imageUrl.filter(
      (elementImage) => elementImage.uri !== itemImage.uri
    );
    this.setState({
      imageUrl: finalData,
    });
  };

  removeDoc = (itemDoc: IImageUrl) => {
    let finalData = this.state.documentUrl.filter(
      (elementDoc) => elementDoc.uri !== itemDoc.uri
    );
    this.setState({
      documentUrl: finalData,
    });
  };
  isDateValid(date: Date) {
    let validDate = moment(date.toString(), "DD/MM/YYYY", true).isValid();
    let validDate1 = moment(date.toString(), "DD-MM-YYYY", true).isValid();

    if (validDate || validDate1) {
      return date;
    } else {
      return moment(date).format("DD/MM/YYYY");
    }
  }

  createProjectPortfolioAPI = async () => {

    let newProjectData = {
      project_portfolio: {
        project_name: this.state.formValues.projectName,
        start_date: this.isDateValid(
          this.state.formValues.startDate
        ) as unknown as string,
        end_date: this.isDateValid(
          this.state.formValues.endDate
        ) as unknown as string,
        url: this.state.formValues.projectUrl,
        description: this.state.formValues.projectDescription,
        email: this.state.formValues.developerEmail,
        full_phone_number: this.state.formValues.phoneNumber,
        full_name: this.state.formValues.developerFullName,
        project_portfolio_attributes: {
          id: this.state.projectDataId,
          project_name: this.state.formValues.projectName,
          team_size: this.state.formValues.teamSize,
          client_details: this.state.formValues.clientDetails,
        },
      },
    };
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    this.setState({ loading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateProjectCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.projectId
        ? `${configJSON.createProjectEndPoint}/${this.state.projectId}`
        : configJSON.createProjectEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(newProjectData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.projectId
        ? configJSON.putAPiEndMethod
        : configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // web events
  handleInputChange = (event: { target: { name: string; value: any; validity: { valueMissing: any; patternMismatch: any; tooShort: any; tooLong: any; valid: any; }; }; }) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [event.target.name]: event.target.value,
      },
    });
    if (event.target.validity.valueMissing) {
      this.setState({
        formError: {
          ...this.state.formError,
          [event.target.name + "Error"]: "Field is required",
        },
      });
    } else if (event.target.validity.patternMismatch) {
      this.setState({
        formError: {
          ...this.state.formError,
          [event.target.name + "Error"]:
            event.target.name === "developerEmail"
              ? "Email is invalid"
              : "Only numbers allowed",
        },
      });
    } else if (
      event.target.validity.tooShort ||
      event.target.validity.tooLong
    ) {
      this.setState({
        formError: {
          ...this.state.formError,
          [event.target.name + "Error"]: "Phone number must be in 1 to 10",
        },
      });
    } else if (event.target.validity.valid) {
      this.setState({
        formError: {
          ...this.state.formError,
          [event.target.name + "Error"]: "",
        },
      });
    }
  };

  handleSelectFiles = (event: { target: { files: any; }; }) => {
    const files = event.target.files;
    if (files && files.length) {
      for (let singaleFile of files) {
        if (singaleFile.type.includes("image") && singaleFile instanceof File) {
          const newImages: IPresignUrl[] = [];
          const newImageUrls: {
            name: string;
            imageUrl: ImageUrl;
            imageObj: Blob;
          }[] = [];
          let fileReader = new FileReader();
          fileReader.readAsDataURL(singaleFile);
          fileReader.onload = (event) => {
            if (event.target) {
              newImageUrls.push({
                name: singaleFile.name,
                imageUrl: event.target.result,
                imageObj: singaleFile,
              });
              newImages.push({
                file_name: singaleFile.name,
                file_size: singaleFile.size,
                category: "image",
              });
              this.setState({
                formValues: {
                  ...this.state.formValues,
                  projectImagesUrl: [
                    ...this.state.formValues.projectImagesUrl,
                    ...newImageUrls,
                  ],
                  projectImages: [
                    ...this.state.formValues.projectImages,
                    ...newImages,
                  ],
                },
              });
            }
          };
        } else {
          this.showAlert("Error", "File type is not allowed!");
        }
      }
    }
  };

  handleSelectDocFiles = (event: { target: { files: any; }; }) => {
    const files = event.target.files;
    if (files && files.length) {
      for (let singaleFile of files) {
        if (
          ["application", "text"].includes(
            singaleFile.type.split("/")[0]
          ) &&
          singaleFile instanceof File
        ) {
          const newDocuments: IPresignUrl[] = [];
          const newDocumentUrls: {
            name: string;
            imageUrl: ImageUrl;
            imageObj: Blob;
          }[] = [];
          let fileReader = new FileReader();
          fileReader.readAsDataURL(singaleFile);
          fileReader.onload = (event) => {
            if (event.target) {
              newDocumentUrls.push({
                name: singaleFile.name,
                imageUrl: event.target.result,
                imageObj: singaleFile,
              });
              newDocuments.push({
                file_name: singaleFile.name,
                file_size: singaleFile.size,
                category: "document",
              });
              this.setState({
                formValues: {
                  ...this.state.formValues,
                  projectDocumentsUrl: [
                    ...this.state.formValues.projectDocumentsUrl,
                    ...newDocumentUrls,
                  ],
                  projectDocuments: [
                    ...this.state.formValues.projectDocuments,
                    ...newDocuments,
                  ],
                },
              });
            }
          };
        } else {
          this.showAlert("Error", "File type is not allowed!");
        }
      }
    }
  };

  handleRemoveImage = (imageName: string) => {
    const filteredImageUrl = this.state.formValues.projectImagesUrl.filter(
      (element) => element.name !== imageName
    );
    let filteredImage;
    if (this.state.projectId) {
      filteredImage = this.state.formValues.projectImages.filter(
        (element: IPresignUrl) => element.file_name !== imageName
      );
    } else {
      filteredImage = this.state.formValues.projectImages.filter(
        (element: IPresignUrl) => element.file_name !== imageName
      );
    }
    this.setState({
      formValues: {
        ...this.state.formValues,
        projectImagesUrl: filteredImageUrl,
        projectImages: filteredImage,
      },
    });
  };

  handleRemoveDoc = (documentName: string) => {
    const filteredDocUrl = this.state.formValues.projectDocumentsUrl.filter(
      (element) => element.name !== documentName
    );
    let filteredDoc;
    if (this.state.projectId) {
      filteredDoc = this.state.formValues.projectDocuments.filter(
        (element: IPresignUrl) => element.file_name !== documentName
      );
      const removedDoc = this.state.formValues.projectDocuments.find(
        (element) => element.file_name === documentName
      );
    } else {
      filteredDoc = this.state.formValues.projectDocuments.filter(
        (element: IPresignUrl) => element.file_name !== documentName
      );
    }
    this.setState({
      formValues: {
        ...this.state.formValues,
        projectDocumentsUrl: filteredDocUrl,
        projectDocuments: filteredDoc,
      },
    });
  };

  handleSubmit = async () => {
    const isFormValues = Object.values(this.state.formValues).every(
      (element) => Object.values(element).length
    );
    const isFormErrors = Object.values(this.state.formError).some(
      (element) => element
    );
    if (!isFormValues || isFormErrors) {
      this.showAlert("Error", "Please enter valid information");
      return false;
    }
    this.setState({ isDisabled: true });
    if (!this.state.isDisabled)
      this.createProjectPortfolioAPI();
  };
  goBack() {
    this.props.navigation.goBack();
  }

  onFormCloseHandler = () => {
    const redirectTo = sessionStorage.getItem("redirectTo") || "Signposting"
    const botId = sessionStorage.getItem("botId")

    let navigationMessage = new Message(getName(MessageEnum.NavigationSignpostingMessage))

    if (redirectTo === "bot" && botId) {
      navigationMessage = new Message(getName(MessageEnum.NavigationIndividualBotMessage))
      navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), botId)
    }
    sessionStorage.removeItem("redirectTo")
    sessionStorage.removeItem("botId")

    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      { navigation: this.props.navigation }
    )

    this.send(navigationMessage)

  }
  handleChangeSelectedPractieValue = (value: number[]) => {
    let error: string | undefined = undefined
    if (value.length === 0 && !this.state.isGlobal) {
      error = "Please select form association"
    }
    this.setState({ selectedPracticeValue: value, selectPracticeError: error, showPracticeError: true })
  }


  handleGolbalSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      isGlobal: event.target.checked,
      selectedPracticeValue: [],
      showPracticeError: false
    })
  }
  handleCloseError = (stateKey: Extract<keyof S, "showOptionError" | "showPracticeError">) => () => {
    this.setState({ ...this.state, [stateKey]: false })
  }

  showBulkModal = () => {
    this.setState(() => ({ openBulkModal: true }))
  }
  closeBulkModal = () => {
    this.setState((prev) => ({ openBulkModal: false, fileData: null, fileName: "", fileUrl: "", errors: { ...prev.errors, fileError: "" } }))
  }
  handleChange = (key: string, value: string) => {
    this.setState((prev) => ({
      organisation: {
        ...prev.organisation,
        [key]: value
      }
    }))
  }
  async handleLogout() {
    await removeStorageData("authToken")
    await removeStorageData("userId")
    sessionStorage.clear()
    const message: Message = new Message(
      getName(MessageEnum.NavigationSignupLoginMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleShowError = () => {
    this.setState({ showError: !this.state.showError })
  }
  handleFileChange = (event: { target: { files: any[]; }; }) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileType = file.type;
    const fileSize = file.size / 1024 / 1024; // size in MB

    if (fileType !== 'text/csv') {
      this.setState((prev) => ({ errors: { ...prev.errors, fileError: "Only CSV files are allowed." }, fileName: null, showError: true, fileData: null }));
      return;
    }

    if (fileSize > 10) {
      this.setState((prev) => ({ errors: { ...prev.errors, fileError: "File size should not exceed 10MB." }, fileName: null, showError: true, fileData: null }));
      return;
    }
    const url = URL.createObjectURL(file);
    this.setState((prev) => ({ errors: { ...prev.errors, fileError: "" }, fileName: file.name, showError: false, fileUrl: url, fileData: file }));
  };
  handleViewFile = () => {
    const csvContent = `Type Id,Organisation Name,Organisation Code,Service Type,Access Route,Website URL,Contact number,Street,Town,Postcode,Country,Criteria,Opening times,Associated practice name,Tags,is_global
      ,,,,self-book/self-referral form/telephone call/email
    `

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = 'Signposting upload template.csv';

    document.body.appendChild(downloadLink);
    downloadLink.click();
  };
  fetchSignPostingData() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const newRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    newRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    newRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllSignPostingAPIEndPoint}/${this.state.signPostingId}`
    )
    newRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    )

    this.apiGetSignPostingId = newRequestMessage.messageId
    runEngine.sendMessage(newRequestMessage.id, newRequestMessage)
  }

  saveSignPostingData = async () => {
    if (this.validateForm()) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": this.state.token,
      };
      const httpBody = {
        "sign_posting": {
          type_id: this.state.organisation.type_id,
          organisation_name: this.state.organisation.organisation_name,
          organisation_code: this.state.organisation.organisation_code,
          service_type: this.state.organisation.service_type,
          access_route: this.state.organisation.access_route,
          website_url: this.state.organisation.website_url,
          contact_number: this.state.organisation.contact_number,
          street: this.state.organisation.street,
          town: this.state.organisation.town,
          postcode: this.state.organisation.postcode,
          country: this.state.organisation.country,
          criteria: this.state.organisation.criteria,
          tag: this.state.selectedTag,
          opening_times: this.state.organisation.opening_times,
          practice: this.state.selectedPracticeValue,
          is_global: this.state.isGlobal
        }
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getSubmitpostDetailCallID = requestMessage.messageId;
      if (!this.state.signPostingId) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getAllSignPostingAPIEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postAPiEndMethod
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getAllSignPostingAPIEndPoint}/${this.state.signPostingId}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.putAPiEndMethod
        );
      }

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  handleResponseForSavingPostData = (message: Message) => {
    if (
      this.getSubmitpostDetailCallID !== null &&
      this.getSubmitpostDetailCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.sign_posting) {
        this.onFormCloseHandler()
        toast.success(responseJson.message)
      }
    }
  }
  saveCSVFileData = async () => {
    if (this.validateBulkUploadForm()) {
      const header = {
        "token": this.state.token,
      };
      const formdata = new FormData();
      formdata.append(
        "file",
        this.state.fileData,
        this.state.fileData.name
      );
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getCSVFileCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.bulkUploadCSVAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postAPiEndMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleResponseForSavingCSVData = (message: Message) => {
    if (
      this.getCSVFileCallId !== null &&
      this.getCSVFileCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.message || responseJson?.created_records) {
        this.onFormCloseHandler()
        toast.success(responseJson?.message || "Your data was successfully uploaded")
      } else {
        toast.error(responseJson?.error || "Couldn't upload data, please try again", { className: "error__toast" })
      }
    }
  }

  handleResponseForgetSignPosting = (message: Message) => {
    if (
      this.apiGetSignPostingId !== null &&
      this.apiGetSignPostingId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        const { is_global, opening_times, ...organisationData } = responseJson.data.attributes;
        const formatOpeningTime = opening_times?.split(":")?.map((time: string) => time?.padStart(2, "0"))?.join(":")
        this.setState({ organisation: { ...organisationData, opening_times: formatOpeningTime }, isGlobal: is_global, selectedPracticeValue: responseJson.data.attributes.practice ?? [], selectedTag: responseJson.data.attributes.tag ?? [] });
      }
    }
  }
  validateForm = () => {

    const errors = { practiceValue: "", tagName: "", formDataError: "", selectPracticeError: "", fileError: "" }
    let isValid = true;

    if (!this.state.selectedPracticeValue.length && !this.state.isGlobal) {
      this.setState({ selectPracticeError: "Please select form association", showPracticeError: true })
      isValid = false
    }

    this.setState({ errors });
    return isValid;
  }

  validateBulkUploadForm = () => {

    const errors = { practiceValue: "", tagName: "", formDataError: "", selectPracticeError: "", fileError: "" }
    let isValid = true;

    if (!this.state.fileData) {
      this.setState((prev) => ({ errors: { ...prev.errors, fileError: "Please select CSV file" }, showError: true }))
      return
    }

    this.setState({ errors });
    return isValid;
  }

  handleTagsApply = (values: number[]) => {
    this.setState({selectedTag: values})
  }
}

// Customizable Area End
