import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { runEngine } from "../../../../framework/src/RunEngine";
import { utils } from "../utils.web";


export type ColorPickerProps = {
    type: "header" | "background";
    colors: string[];
    handleColorChange: (color: string) => void;
    selectedColor: string;
    title: string;
    iconColor: string;
    initialColor: string;
}

interface S {
    open: boolean;
    optionColor: string;
    hex: string;
    rgb: {
        r: number;
        g: number;
        b: number;
    };
}

interface SS {}

export default class ColorPickerController extends BlockComponent<ColorPickerProps, S, SS> {

    colorBtnRef = React.createRef<HTMLButtonElement>()
    colorOptions = this.props.colors.map(color => ({ label: color, value: color }))

    constructor(props: ColorPickerProps) {
        super(props)

        this.subScribedMessages = []

        this.state = {
            open: false,
            optionColor: this.props.initialColor,
            hex: this.props.selectedColor,
            rgb: utils.hexToRgb(this.props.selectedColor),
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    handleOpenColorPicker = () => {
        this.setState({optionColor: this.props.selectedColor, open: true})
    }

    handleHexColorChange = (color: string) => {
        const rgb = utils.hexToRgb(color)
        this.setState({hex: color, rgb: rgb})
    }

    handleCloseColorPicker = () => {
        this.handleHexColorChange(this.props.selectedColor)
        this.setState({open: false})
    }

    handleRGBChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const newRGB = { ...this.state.rgb, [name]: value }
        const newHex = utils.rgbToHex(newRGB.r, newRGB.g, newRGB.b)
        this.setState({rgb: newRGB, hex: newHex})
    }

    handleOptionChange = (color: string) => {
        this.setState({optionColor: color})
        this.handleHexColorChange(color)
    }

    handleApplyColorChange = () => {
        this.props.handleColorChange(this.state.hex)
        this.setState({open: false})
    }
    
}
