import React from "react";

import {
  // Customizable Area Start
  Typography,
  createTheme,
  ThemeProvider,
  styled,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { DragIndicatorOutlined, DeleteOutlineRounded } from "@material-ui/icons";
import {
  Props,
} from "./AutomaticFormCreationController.web";
import Sidebar from "../../../components/src/Sidebar.web";
import FormInputWithLabel from "./components/FormInputWithLabel.web";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import OptionsListFormCreation from "../../../components/src/OptionsListFormCreation.web";
import Header from "./components/Header.web";
import { theme as styledTheme } from "./theme.web";
import Settings from "./components/Settings.web";
import Options from "./components/Options.web";
import AutomaticEditFormController from "./AutomaticEditFormController";

const {FormFieldsContainer, Column, StyledRow} = styledTheme

const AutomaticFormCreationContainer = styled("div")({
  display: "flex",
  backgroundColor: "#FAFAFA",
})

const FormFieldHeader = styled("div")({
  display: "flex",
  gap: 8,
  alignItems: "center",
  '& button': {
    all: "unset",
    cursor: "pointer",
  },
  '& .ml-auto': {
    marginLeft: 'auto',
  },
  '& .grab': {
    cursor: "grab",
  },
})

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    fontFamily: "Cairo",
    subtitle1: {
      margin: 0,
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "26px",
      color: "#0F172A",
    }
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: "#475569",
        fontFamily: "Cairo",
        fontSize: 14,
        lineHeight: "22px",
        fontWeight: 700,
      },
    },
  },
});
// Customizable Area End

export default class AutomaticEditForm extends AutomaticEditFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <AutomaticFormCreationContainer>
          <Sidebar navigation={this.props.navigation} activeMenu="forms" />
          <Column>
            {!this.state.formDataError ? <>
            <Header loading={this.state.saveFormLoading} onSaveClick={this.handleFormSave} onClose={this.onFormCloseHandler} />
            <StyledRow className="flex-1" >
              <Column className="gap-8" style={{ padding: "12px 48px" }}>
                <FormFieldsContainer>
                  <h4 className="title" >Add option</h4>
                  <Typography variant="subtitle1" >
                    Customize options to create the form you need
                  </Typography>
                  <Options data-testid="all-options" questionsLength={this.state.formQuestions.length} handleAddField={this.handleAddField} />
                </FormFieldsContainer>
                <FormFieldsContainer>
                  <h4 className="title" >Form name</h4>
                  <FormInputWithLabel
                    label="Name*"
                    placeholder="Type here"
                    id="formName"
                    name="formName"
                    value={this.state.formName}
                    formError={this.state.formNameError}
                    onValueChange={this.handleFormNameChange}
                    maxLength={50}
                  />
                </FormFieldsContainer>
                {
                  this.state.formQuestions.length > 0 ? (
                    <DragDropContext data-testid="drag-drop-context" onDragEnd={this.handleQuestionDragEnd} >
                      <Droppable droppableId="form_questions" >
                        {(droppableProvided) => (
                          <Column ref={droppableProvided.innerRef} {...droppableProvided.droppableProps} className="gap-8">
                            {
                              this.state.formQuestions.map((formQuestion, formQuestionNum) => (
                                <Draggable key={formQuestion.id} index={formQuestionNum} draggableId={`${formQuestion.id}`} >
                                  {(draggableProvided) => (
                                    <FormFieldsContainer data-testid={`${formQuestion.type}_${formQuestionNum + 1}`} ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} >
                                      <FormFieldHeader>
                                        <button {...draggableProvided.dragHandleProps} className="grab" aria-label={`Drag ${formQuestion.questionTitle}-${formQuestionNum + 1}`} >
                                          <DragIndicatorOutlined htmlColor="#64748B" />
                                        </button>
                                        <h4 className="title" >{formQuestion.questionTitle}</h4>
                                        <button className="ml-auto" onClick={this.handleDeleteQuestion(formQuestion.id)} aria-label={`remove question - ${formQuestionNum + 1}`} >
                                          <DeleteOutlineRounded htmlColor="#64748B" />
                                        </button>
                                      </FormFieldHeader>
                                      <FormInputWithLabel
                                        id={`question_${formQuestion.id}`}
                                        name={`question_${formQuestionNum + 1}`}
                                        formError={formQuestion.questionError}
                                        value={formQuestion.question}
                                        onValueChange={this.handleFormQuestionValueChange}
                                      />
                                      {
                                        formQuestion.options && (
                                          <OptionsListFormCreation 
                                            questionId={formQuestion.id}
                                            questionIndex={formQuestionNum + 1}
                                            options={formQuestion.options}
                                            showError={this.state.showOptionError}
                                            optionError={formQuestion.optionError}
                                            handleCloseError={this.handleCloseError("showOptionError")}
                                            handleUpdateOptionsInQuestion={this.handleUpdateOptionsInQuestion}
                                          />
                                        )
                                      }
                                    </FormFieldsContainer>
                                  )}
                                </Draggable>
                              ))
                            }
                          </Column>
                        )}
                      </Droppable>
                    </DragDropContext>
                  ) : null
                }
              </Column>
              <Settings 
                practices={this.state.practices}
                selectedPracticeValue={this.state.selectedPracticeValue}
                selectPracticeError={this.state.selectPracticeError}
                isGlobal={this.state.isGlobal}
                handleChangeSelectedPractieValue={this.handleChangeSelectedPractieValue}
                handleGolbalSwitchChange={this.handleGolbalSwitchChange}
                showError={this.state.showPracticeError}
                handleCloseError={this.handleCloseError("showPracticeError")}
              />
            </StyledRow>
            </> : <Typography variant="subtitle1" style={{textAlign: "center", fontSize: "18px"}}>No data found</Typography>}
          </Column>
        </AutomaticFormCreationContainer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
