import React from "react";

// Customizable Area Start
import { Typography, createTheme, ThemeProvider, Button, styled } from "@material-ui/core";
import Sidebar from "../../../components/src/Sidebar.web";
import CustomAppHeader from "../../../components/src/CustomAppHeader.web";
import { theme as styledTheme } from "./theme.web";
const { FormFieldsContainer, StyledRow, Column } = styledTheme
import Spinner from "../../../blocks/customform/src/components/Spinner.web";
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON,
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={Theme}>
        <LandingPage>
          <Sidebar navigation={this.props.navigation} activeMenu="audit" />
          <div className="rightside-content">
            <CustomAppHeader title="Audit Log" navigation={this.props.navigation} showSearchBox={true} searchInputVal={this.state.globalSearch} onSearchInputChange={this.onSearchInputChange} />
            <StyledRow className="audit-log flex-1" style={{
              padding: "12px 48px", gap: "12px",
              width: "calc(100% - 100px)"
            }}>
              <FormFieldsContainer style={{ width: "100%", minHeight: "788px" }}>
                {!this.state.isLoading ? (
                  <>
                  <Column style={{ gap: "12px", padding: "24px 20px 0 24px" }}>
                      {this.state.history.map((date: any, i: number) => (
                        <StyledRow className="flex-column" style={{ gap: "24px" }}  key={i}>
                          <Typography style={{ fontWeight: 700, fontFamily: "Inter", fontSize: "24px", color: "#000000" }}>{this.getFormatDate(date)}</Typography>
                          {this.state.activities.filter((log: any) => {
                            if(this.validateDate(date, log.updated_at)) return log
                          }).map((log: any) => (
                            <StyledRow className="items-center" style={{ gap: "24px", padding: "6px 0" }} key={log.id}>
                                <Typography style={{width: "5%", textAlign: "center"}}>{this.handleLastUpdate(log.updated_at)}</Typography>
                                <StyledRow className="flex-column" style={{ borderLeft: "4px solid #51ABB3", padding: "0 0 0 24px", gap: "4px", width: "95%" }}>
                                  <Typography component={"h6"} style={{ color: "#000000" }}>{log.comment}</Typography>
                                  <Typography>{this.getFormatTime(log.updated_at)}</Typography>
                                </StyledRow>
                              </StyledRow>
                          ))}
                        </StyledRow>
                      ))}
                  </Column>
                  <Button 
                    style={{ color: "#51ABB3", fontWeight: 700, fontFamily: "Cairo", fontSize: "16px", textTransform: "inherit", width: "137px", height: "56px" }}
                    onClick={this.handleLoadMore}
                  >
                    <span>{`${this.state.loadMoreLoading ? "Loading..." : "Load more"}`}</span>
                    {!this.state.loadMoreLoading && <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.1418 6.35097C16.5118 4.72097 14.2018 3.78097 11.6618 4.04097C7.99184 4.41097 4.97184 7.39097 4.56184 11.061C4.01184 15.911 7.76184 20.001 12.4918 20.001C15.6818 20.001 18.4218 18.131 19.7018 15.441C20.0218 14.771 19.5418 14.001 18.8018 14.001C18.4318 14.001 18.0818 14.201 17.9218 14.531C16.7918 16.961 14.0818 18.501 11.1218 17.841C8.90184 17.351 7.11184 15.541 6.64184 13.321C5.80184 9.44097 8.75184 6.00097 12.4918 6.00097C14.1518 6.00097 15.6318 6.69097 16.7118 7.78097L15.2018 9.29097C14.5718 9.92097 15.0118 11.001 15.9018 11.001H19.4918C20.0418 11.001 20.4918 10.551 20.4918 10.001V6.41097C20.4918 5.52097 19.4118 5.07097 18.7818 5.70097L18.1418 6.35097Z" fill="#51ABB3" />
                    </svg>}
                  </Button>
                  </>
                ): <Spinner spinnerModal={this.state.isLoading} />}
              </FormFieldsContainer>
            </StyledRow>
          </div>
        </LandingPage>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Theme = createTheme({
  zIndex: {
    modal: 1300
  },
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        '& span': {
          gap: "8px"
        },
        '&.Mui-primary': {
          backgroundColor: "#51ABB3",
          borderRadius: 8,
          padding: "6px 10px",
          marginLeft: "auto",
          textTransform: "none",
        },
        '&.Mui-primary $label': {
          color: "#FFFFFF",
          fontWeight: 700,
          fontSize: 16, fontFamily: "Cairo",
          lineHeight: "24px",
        },
      },
    },
    MuiMenu: {
      paper: {
        width: "149px",
        boxShadow: "0px 2px 8px 0px #00000014",
      },
      list: {
        fontFamily: "Cairo",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        textAlign: "left",
      }
    },
    MuiTooltip: {
      popper: {
        backgroundColor: "#fff !important",
        display: "flex",
        alignItems: "center"
      },
      tooltip: {
        color: "#0F172A",
        margin: "0 !important",
        "& h1": {
          fontSize: "14px",
          borderRadius: "8px",
          fontFamily: "Cairo",
          boxShadow: "0px 2px 8px 0px #00000014",
          textTransform: "capitalize",
          padding: "6px 10px",
        }
      }
    }
  },
});
const LandingPage = styled("section")({
  display: "flex",
  alignItems: "stretch",
  justifyContent: "stretch",
  zIndex: 1,

  "& .rightside-content": {
    width: "calc(100% - 100px)",
    backgroundColor: "#FAFAFA",
  },
  "& .audit-log p": {
    fontFamily: "Cairo",
    color: '#475569',
    fontSize: "14px",
    marginBottom: 0
  },
  "& h6": {
    fontFamily: "Cairo",
    color: '#000',
    fontSize: "16px",
    marginBottom: 0
  },
  "& .MuiButton-root:hover": {
    background: "transparent"
  },

})
// Customizable Area End
