import React from "react";

import {
  // Customizable Area Start
  Drawer,
  List,
  Box,
  Typography,
  styled,
  Tabs,
  Tab,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  LinkRounded,
  TollRounded,
  ListRounded,
  AllInboxRounded,
  CallSplitRounded,
  UpdateRounded,
  ArrowRightAltRounded,
  StarBorderRounded,
  CloseRounded,
} from "@material-ui/icons";
import GoToTile from "./GoToTile.web";
import SliderScale from "./SliderScale.web";
import SendMessage from "./SendMessage.web";
import ShareLink from "./ShareLink.web";
import Rating from "./Rating.web";
import Dropdown from "./Dropdown.web";
import SingleOrMultiChoice from "./SingleOrMultiChoice.web";
import ShareVideo from "./ShareVideo.web";
import type { SelectedCard } from "./ChatbotSidePanelController";
import ShareUserGuideOrEmbedForm from "./ShareUserGuideOrEmbedForm.web";
import ProjectTemplates from "../../../projecttemplates/src/ProjectTemplates.web"; 

const SidePanelHeader = styled(Box)({
  padding: "24px 40px",
  display: "flex",
  position: "sticky",
  top: 0,
  zIndex: 4,
  backgroundColor: "#FFFFFF",
})

const Card = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 6,
  width: 100,
  cursor: "pointer",
  '& .icon-box': {
    width: "100%",
    height: 100,
    border: "1px solid #CBD5E1",
    borderRadius: 12,
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 8px 0px #00000014",
    display: "grid",
    placeItems: "center",
    boxSizing: "border-box",
    color: "#334155",
  },
  '&:hover .icon-box': {
    borderColor: "#51ABB3",
    color: "#51ABB3",
  },
  '&:hover .card-label': {
    color: "#51ABB3"
  },
  '&[aria-disabled="true"]': {
    pointerEvents: "none",
  },
})

const DrawerCloseButton = styled(Box)({
  all: "unset",
  cursor: "pointer",
  position: "absolute",
  top: 24,
  zIndex: 5,
  right: 464,
  display: "grid",
  placeItems: "center",
  height: 44,
  width: 44,
  borderRadius: 100,
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 2px 8px 0px #00000014",
})

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      '&.card-label': {
        width: "65px",
        textAlign: "center",
        fontFamily: "Cairo",
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "18px",
        color: "#0F172A",
      },
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "transparent",
        minHeight: "calc(100vh - 92px)",
      },
    },
    MuiDrawer: {
      paperAnchorDockedRight: {
        borderLeftWidth: 0,
      },
      paper: {
        top: 95,
        height: "80%",
        background: 'white',
        width: 440,
        boxShadow: "0px 2px 8px 0px #00000014",
        overflowY:'visible',
        '& .zero-scroll-width': {
          scrollbarWidth: "none",
        },
        '& .zero-scroll-width::-webkit-scrollbar': {
          width: 0,
        }
      },
    },
    MuiTabs: {
      root: {
        minHeight: 32,
        height: 32,
      },
      indicator: {
        height: 1,
        backgroundColor: "#51ABB3"
      },
    },
    MuiTab: {
      root: {
        fontFamily: "Cairo",
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "24px",
        textTransform: "none",
        minHeight: "32px",
        minWidth: 0,
        padding: "4px 10px",
        color: "#334155",
        letterSpacing: "0.015em",
        '@media (min-width: 600px)': {
          minWidth: 0,
        },
        '&.Mui-selected': {
          color: "#51ABB3",
        },
      },
    },
    MuiList: {
      root: {
        display: "grid",
        gridTemplateColumns: "auto auto auto",
        gap: "24px 14px",
        justifyContent: "space-between",
      },
      padding: {
        paddingTop: 0,
        paddingBottom: 24,
        paddingLeft: 48,
        paddingRight: 48,
      },
    },
  },
});

type SidePanelCard = {
  title: string;
  value: SelectedCard;
  icon: React.ReactNode;
}

// Customizable Area End

import ChatbotSidePanelController, { Props } from "./ChatbotSidePanelController";
import EndChat from "./EndChat.web";
import SendIcon from "./SendIcon.web";
import DocumentIcon from "./DocumentIcon.web";
import VideoIcon from "./VideoIcon.web";
import BookIcon from "./BookIcon.web";
import CategoryIcon from "./CategoryIcon.web";
import CopyIcon from "./CopyIcon.web";
import CriteriaRouting from "../../../cfconnectorlogic/src/Cfconnectorlogic.web";
import OpenAiIntegration from "../../../cfmicrosoftazureopenaiintegration1/src/Cfmicrosoftazureopenaiintegration1.web";

export default class ChatbotSidePanel extends ChatbotSidePanelController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  sidePanelCards2: SidePanelCard[] = [
    {
      title: "Send Message",
      value: "send_message",
      icon: <SendIcon />,
    },
    {
      title: "Share User guide",
      value: "share_user_guide",
      icon: <DocumentIcon />,
    },
    {
      title: "Share Video",
      value: "share_video",
      icon: <VideoIcon />,
    },
    {
      title: "Share Link(s)",
      value: "share_link",
      icon: <LinkRounded htmlColor="currentColor" />,
    },
    {
      title: "Single Choice",
      value: "single_choice",
      icon: <TollRounded htmlColor="currentColor" />,
    },
    {
      title: "Multi Choice",
      value: "multi_choice",
      icon: <ListRounded htmlColor="currentColor" />,
    },
    {
      title: "Dropdown",
      value: "dropdown",
      icon: <AllInboxRounded htmlColor="currentColor" />
    },
    {
      title: "End chat",
      value: "end_chat",
      icon: <BookIcon />,
    },
    {
      title: "Go to tile",
      value: "go_to_tile",
      icon: <CallSplitRounded htmlColor="currentColor" />,
    },
    {
      title: "Criteria Routing",
      value: "criteria_routing",
      icon: <UpdateRounded htmlColor="currentColor" />,
    },
    {
      title: "Slider Scale",
      value: "slider_scale",
      icon: <ArrowRightAltRounded htmlColor="currentColor" />,
    },
    {
      title: "Rating",
      value: "rating",
      icon: <StarBorderRounded htmlColor="currentColor" />,
    },
    {
      title: "OpenAI Integration",
      value: "openai",
      icon: <CategoryIcon />,
    },
    {
      title: "Embed Form",
      value: "embed_form",
      icon: <CopyIcon />,
    }
  ]

  renderMultiChoiceCard = () => (
    this.props.selectedCard === "multi_choice" ? (
      <SingleOrMultiChoice 
        type="multi_choice"
        data-testid="multiChoiceCard" 
        onClose={this.props.handleCloseSelectedCard} 
        loading={this.state.loading} 
        onSave={this.handleSingleChoiceSave}
        title="Multi choice"
        initialOptions={this.props.selectedCardData?.options ?? ["Option 1", "Option 2","Option 3"]} 
        name={this.props.selectedCardData?.title ?? ""}
        fieldName={this.props.selectedCardData?.fieldName ?? ""}
        message={this.props.selectedCardData?.message ?? ""}
        id={this.props.selectedCardData?.id}
        onDrawerClose={this.handleCloseDrawer}
        botContext={this.props.botContext}
      />
    ) : null
  )

  renderSingleChoiceCard = () => (
    this.props.selectedCard === "single_choice" ? (
      <SingleOrMultiChoice 
        type="single_choice" 
        data-testid="singleChoiceCard" 
        onClose={this.props.handleCloseSelectedCard} 
        loading={this.state.loading} 
        onSave={this.handleSingleChoiceSave}
        title="Single choice"
        name={this.props.selectedCardData?.title ?? ""}
        message={this.props.selectedCardData?.message ?? ""}
        id={this.props.selectedCardData?.id}
        fieldName={this.props.selectedCardData?.fieldName ?? ""}
        onDrawerClose={this.handleCloseDrawer}
        botContext={this.props.botContext}
        initialOptions={this.props.selectedCardData?.options ?? ["Option 1", "Option 2","Option 3"]}
      />
    ) : null
  )

  renderUserGuideCard = () => (
    this.props.selectedCard === "share_user_guide" ? (
      <ShareUserGuideOrEmbedForm 
        data-testid="shareUserGuideCard" 
        navigation={this.props.navigation} 
        onSave={this.handleShareGuideOrEmbedFormSave} 
        onClose={this.props.handleCloseSelectedCard} 
        type="share_user_guide"
        id={this.props.selectedCardData?.id}
        botContext={this.props.botContext}
        title={this.props.selectedCardData?.title}
        message={this.props.selectedCardData?.message}
        selectedOptions={this.props.selectedCardData?.userGuides}
        onDrawerClose={this.handleCloseDrawer}
        token={this.props.token}
        handleLogout={this.handleLogout}
        onSaveLoading={this.state.loading}
      />
    ) : null
  )

  renderEmbedFormCard = () => (
    this.props.selectedCard === "embed_form" ? (
      <ShareUserGuideOrEmbedForm 
        data-testid="embedFormCard" 
        botContext={this.props.botContext}
        navigation={this.props.navigation} 
        onSave={this.handleShareGuideOrEmbedFormSave} 
        onClose={this.props.handleCloseSelectedCard} 
        onSaveLoading={this.state.loading}
        type="embed_form"
        message={this.props.selectedCardData?.message}
        title={this.props.selectedCardData?.title}
        selectedOptions={this.props.selectedCardData?.embedForms}   
        id={this.props.selectedCardData?.id}
        onDrawerClose={this.handleCloseDrawer}
        token={this.props.token}
        handleLogout={this.handleLogout}
      />
    ) : null
  )

  renderCriteriaRouting = () => (
    this.props.selectedCard === "criteria_routing" ? (
      <CriteriaRouting
        key={this.state.cardUpdateTime}
        data-testid="criteriaRoutingCard" 
        navigation={this.props.navigation}
        id="criteria-routing"
        onSaveClick={this.handleCriteriaRoutingSave}
        onClose={this.props.handleCloseSelectedCard}
        onDrawerClose={this.handleCloseDrawer}
        loading={this.state.loading}
        initialCriteriaRoutings={this.props.selectedCardData?.criteriaRoutings ?? []}
        name={this.props.selectedCardData?.title}
        cardId={this.props.selectedCardData?.id}
        contextVariables={this.props.contextVariables}
      />
    ) : null
  )

  renderOpenAiIntegration = () => (
    this.props.selectedCard === "openai" ? (
      <OpenAiIntegration 
        navigation={this.props.navigation}
        id="Open AI"
        data-testid="openAiCard"
        cardId={this.props.selectedCardData?.id}
        botContext={this.props.botContext}
        loading={this.state.loading}
        onClose={this.props.handleCloseSelectedCard}
        onDrawerClose={this.props.onDrawerClose}
        onSaveClick={() => {}}
        allActionCards={this.props.allActionCards}
      />
    ) : null
  )

  handleDisableOpenAiCard = (cardValue: SelectedCard) => {
    if(cardValue === "openai") {
      return !this.props.isUserEdatt
    }
    return false
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Drawer
          anchor="right"
          open={this.props.isDrawerOpen}
          BackdropComponent={() => <div id="backdrop" />}
          data-test-id="side-panel"
          style={{ zIndex: 999,position:"relative"}}
          PaperProps={{style: {top: this.props.sidebarTop}}}
        > 
          <DrawerCloseButton component="button"  aria-label="close card drawer" onClick={this.handleCloseDrawer} >
            <CloseRounded htmlColor="#0F172A" />
          </DrawerCloseButton>
          {
            this.props.selectedCard === null &&
              <div
                className="zero-scroll-width"
                style={{ backgroundColor: "white", overflowY:"auto"}}
              >
                {
                  !this.props.isTemplate && (
                    <SidePanelHeader data-testid="sidepanel-header" >
                      <Tabs value={this.state.tab} onChange={this.onTabChange} >
                        <Tab disableRipple label="Cards" value="cards" />
                        <Tab disableRipple label="Templates" value="templates" />
                      </Tabs>
                    </SidePanelHeader>
                  )
                }
                {
                  this.state.tab === "cards" ? (
                    <List style={{paddingTop: this.props.isTemplate ? "24px" : 0}} >
                      {this.sidePanelCards2.map((card, index) => (
                        <Card component="li" aria-disabled={this.handleDisableOpenAiCard(card.value)} data-testid={card.title} key={index}  onClick={() => this.props.handleOpenSelectedCard(card.value)}>
                          <Box className="icon-box">{card.icon}</Box>
                          <Typography className="card-label">{card.title}</Typography>
                        </Card>
                      ))}
                    </List>
                  ) : (
                    <div>
                      <ProjectTemplates 
                        id="template" 
                        navigation={this.props.navigation}
                        handleAddConnectorCardsFromTemplateToChatbot={this.props.handleAddConnectorCardsFromTemplateToChatbot} 
                      />
                    </div>  
                  )
                }
              </div>
            }
          {this.renderSingleChoiceCard()}
          {this.renderMultiChoiceCard()}
          {this.renderCriteriaRouting()}
          {this.renderOpenAiIntegration()}
          {
            this.props.selectedCard === "go_to_tile" && (
              <GoToTile 
                data-testid="goToTileCard" 
                loading={this.state.loading} 
                onClose={this.props.handleCloseSelectedCard} 
                onSave={this.handleGoToTileSave} 
                targetCardOptions={this.props.targetCards}
                {...this.props.selectedCardData}
                targetCard={`${this.props.selectedCardData?.targetCard ?? ""}`}
                onDrawerClose={this.handleCloseDrawer} 
              />
            )
          }
          {
            this.props.selectedCard === "slider_scale" && (
              <SliderScale 
                loading={this.state.loading} 
                data-testid="sliderScaleCard" 
                onClose={this.props.handleCloseSelectedCard} 
                onSubmit={this.handleSliderScaleSave}
                onDrawerClose={this.handleCloseDrawer} 
                botContext={this.props.botContext}
                {...this.props.selectedCardData}
              />
            )
          }
          {
            this.props.selectedCard === "send_message" && (
              <SendMessage 
                loading={this.state.loading} 
                data-testid="sendMessageCard" 
                onClose={this.props.handleCloseSelectedCard} 
                onSubmit={this.handleSendMessageSave}
                onDrawerClose={this.handleCloseDrawer}
                botContext={this.props.botContext}
                {...this.props.selectedCardData} 
              />
            )
          }
          {
            this.props.selectedCard === "share_link" && (
              <ShareLink 
                loading={this.state.loading} 
                data-testid="shareLinkCard" 
                onClose={this.props.handleCloseSelectedCard} 
                onSave={this.handleShareLinkSave}
                onDrawerClose={this.handleCloseDrawer}
                title={this.props.selectedCardData?.title}
                message={this.props.selectedCardData?.message}
                id={this.props.selectedCardData?.id}
                initialLinks={this.props.selectedCardData?.links}
                botContext={this.props.botContext}
              />
            )
          }
          {
            this.props.selectedCard === "rating" && (
              <Rating 
                loading={this.state.loading} 
                data-testid="ratingCard" 
                onClose={this.props.handleCloseSelectedCard} 
                onSave={this.handleRatingSave}
                onDrawerClose={this.handleCloseDrawer}
                botContext={this.props.botContext}
                {...this.props.selectedCardData} 
              />
            )
          }
          {
            this.props.selectedCard === "end_chat" && (
              <EndChat 
                loading={this.state.loading} 
                data-testid="endChatCard" 
                onClose={this.props.handleCloseSelectedCard} 
                onSubmit={this.handleSendMessageSave}
                onDrawerClose={this.handleCloseDrawer} 
                botContext={this.props.botContext}
                {...this.props.selectedCardData}
              />
            )
          }
          {
            this.props.selectedCard === "dropdown" && (
              <Dropdown  
                data-testid="dropdownCard" 
                loading={this.state.loading} 
                onClose={this.props.handleCloseSelectedCard} 
                onSave={this.handleDropdownCardSave}
                onDrawerClose={this.handleCloseDrawer}
                botContext={this.props.botContext}
                {...this.props.selectedCardData}
              />
            )
          }
          { this.renderUserGuideCard() }
          { this.renderEmbedFormCard() }
          {
            this.props.selectedCard === "share_video" && (
              <ShareVideo
                data-testid="shareVideoCard" 
                loading={this.state.loading}
                onClose={this.props.handleCloseSelectedCard}
                onSave={this.handleShareVideoSave}
                onDrawerClose={this.handleCloseDrawer}
                botContext={this.props.botContext}
                {...this.props.selectedCardData}
              />
            )
          }
        </Drawer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
