import { Avatar, InputLabel, OutlinedInput, Popover, ThemeProvider, createTheme, styled } from "@material-ui/core";
import { DeleteOutline, Edit } from "@material-ui/icons";
import React from "react";
import { defaultChatLogo } from "../assets";
import ImageCropDialog from "../../../../components/src/ImageCropDialog.web";
import ImageFileInput from "../../../../components/src/ImageFileInput.web";
import ChatbotProfilePictureController, {ChatbotProfilePictureProps} from "./ChatbotProfilePictureController";

const theme = createTheme({
    typography: {
        h3: {
            fontFamily: "Cairo",
            fontWeight: 700,
            fontSize: 24,
            lineHeight: "32px",
            color: "#0F172A",
        },
        h4: {
            fontFamily: "Cairo",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "32px",
            color: "#0F172A",
        },
        subtitle1: {
            fontFamily: "Roboto",
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "24px",
            color: "#94A3B8",
        },
    },
    overrides: {
        MuiFormLabel: {
            root: {
                fontFamily: "Inter",
                fontWeight: 700,
                fontSize: 14,
                lineHeight: "22px",
                color: "#475569",
                '&.zoom': {
                    fontWeight: 500,
                    lineHeight: "24px",
                    fontFamily: "Roboto",
                    color: "#51ABB3",
                },
            },
        },
        MuiButton: {
            root: {
              textTransform: "none",
              borderRadius: 8,
              padding: "16px",
              width: 105,
              flex: 1,
              '&.dialogFooterBtn': {
                flex: "0 0 105px",
                padding: 16,
              },
              '&:hover': {
                  backgroundColor: "transparent",
                  boxShadow: 'none',
              },
              '&.Mui-secondary': {
                  backgroundColor: "#E5F6FF",
                  color: "#51ABB3",
              },
              '&.Mui-primary': {
                  color: "#FFFFFF",
                  backgroundColor: "#51ABB3",
              },
              '&.Mui-secondary:hover': {
                backgroundColor: "#E5F6FF",
                boxShadow: 'none',
              },
              '&.Mui-primary:hover': {
                backgroundColor: "#51ABB3",
              },
            },
            label: {
                fontFamily: "Cairo",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "24px",
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 8,
                '&:hover $notchedOutline': {
                    borderColor: "#CBD5E1",
                },
                '&.Mui-Focused $notchedOutline': {
                    borderColor: "#CBD5E1",
                    borderWidth: 1,
                },
            },
            notchedOutline: {
                borderWidth: 1,
                borderColor: "#CBD5E1",
            },
            input: {
                padding: "16px 8px",
            },
        },
        MuiInputBase: {
            input: {
                color: "#64748B",
                fontFamily: "Inter",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
                height: "auto",
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: '#FAFAFA',
            },
        },
        MuiPaper: {
            root: {
                backgroundCoor: "#FFFFFF",
                width: 568,
            },
            elevation24: {
                boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017"
            },
            rounded: {
                borderRadius: 24,
            }
        },
        MuiDialogActions: {
            root: {
                padding: "24px 40px",
                gap: 10,
                borderTop: "1px solid #CBD5E1", 
            },
            spacing: {
                '& > :not(:first-child)': {
                    marginLeft: 0
                }
            }
        },
        MuiSlider: {
            root: {
                color: "#51ABB3",
                padding: 0,
            },
            rail: {
                backgroundColor: "#94A3B8",
                height: 4,
                borderRadius: 100,
            },
            track: {
                height: 4,
                backgroundColor: "#51ABB3",
                borderRadius: 100,
            },
            thumb: {
                boxShadow: "none",
                '&:hover': {
                    boxShadow: "none",
                },
                '&.Mui-focusVisible': {
                    boxShadow: "none",
                },
            },
            active: {
                boxShadow: "none",
            },
        },
        MuiAvatar: {
            root: {
                width: "100%",
                height: "100%",
            },
        },
    },
})

const Root = styled("div")({
    display: "flex",
    gap: 12,
    alignItems: "flex-start",
})

const FormControlBox = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 4,
    width: "400px",
})

const ProfilePicture = styled("div")({
    height: 73,
    width: 73,
    borderRadius: 70,
    position: "relative",
})

const EditProfilePictureButton = styled("button")({
    all: "unset",
    position: "absolute",
    cursor: "pointer",
    right: 0,
    bottom: -9,
    height: 32,
    width: 32,
    display: "grid",
    placeItems: "center",
    borderRadius: 140,
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 8px 0px #00000014",
})

const EditProfileContent = styled("div")({
    width: 192,
    border: "1px solid #E2E8F0",
    borderRadius: 8,
    boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
    backgroundColor: "#FFFFFF",
    padding: "3px 4px",
    boxSizing: "border-box",
})

const UploadAndRemoveButton = styled("button")({
    all: "unset",
    cursor: "pointer",
    padding: "6px 16px",
    boxSizing: "border-box",
    width: "100%",
    borderRadius: 8,
    display: "flex",
    gap: 8,
    alignItems: "center",
    color: "#475569",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "22px",
    fontFamily: "Cairo",
    '&:hover': {
        backgroundColor: "#E6F3F4",
    },
})

export default class ChatbotProfilePicture extends ChatbotProfilePictureController {
    
    constructor(props: ChatbotProfilePictureProps) {
        super(props)
    }
    
    render() {
        return (
            <ThemeProvider theme={theme} >
                <Root>
                    <ProfilePicture >
                        <Avatar alt="chatbot-logo" src={this.props.headerImageURL ?? defaultChatLogo} />
                        <EditProfilePictureButton aria-label="edit-profile" ref={this.editBtnRef} onClick={this.handleOpenEditProfile} aria-expanded={this.state.isEditProfilePicOpen}>
                            <Edit htmlColor="#475569" />
                        </EditProfilePictureButton>
                    </ProfilePicture>
                    <FormControlBox>
                        <InputLabel>Label*</InputLabel>
                        <OutlinedInput 
                            placeholder="Type your label here"
                            fullWidth
                            id="label"
                            name="label"
                            value={this.props.headerLabel}
                            onChange={this.props.handleHeaderLabelChange}
                        />
                    </FormControlBox>
                </Root>
                <Popover
                    id="popover-edit-profile"
                    open={this.state.isEditProfilePicOpen}
                    anchorEl={this.editBtnRef.current}
                    onClose={this.handleCloseEditProfile}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    PaperProps={{ style: { width: 294, boxShadow: "none", backgroundColor: "transparent" } }}
                    aria-expanded={this.state.isEditProfilePicOpen}
                >
                    <EditProfileContent>
                        <ImageFileInput ref={this.fileInputRef} onImageLoad={this.onFileChange} name="chatbot-profile-picture" />
                        <UploadAndRemoveButton id="upload-photo" onClick={this.handleUploadFile}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.75 12.375H11.25V7.875H14.25L9 2.625L3.75 7.875H6.75V12.375ZM9 4.7475L10.6275 6.375H9.75V10.875H8.25V6.375H7.3725L9 4.7475ZM3.75 13.875H14.25V15.375H3.75V13.875Z" fill="#334155"/>
                            </svg>
                            Upload photo
                        </UploadAndRemoveButton>
                        <UploadAndRemoveButton onClick={this.handleRemovePhoto} id="removePhoto">
                            <DeleteOutline style={{height: 18, width: 18}} htmlColor="#334155" />
                            Remove photo
                        </UploadAndRemoveButton>
                    </EditProfileContent>
                </Popover>
                <ImageCropDialog
                    data-test-id="image-crop-dialog"
                    key={this.state.isProfilePicAdjustDialogOpen ? "image-crop-dialog" : "dialog"} 
                    open={this.state.isProfilePicAdjustDialogOpen}
                    handleClose={this.handleCloseProfileAdjustDialog}
                    selectedImage={this.fileRef}
                    onApply={this.onApply}
                />
            </ThemeProvider>
        )
    }
}
