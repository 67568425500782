import React from "react";
import { Bar } from "react-chartjs-2"
import { BarChartData as data } from "../Dashboard4Controller"

const chartSetting = {
    width: 500,
    borderRadius: "8px"
};

const chartData = {
    labels: data.map((data) => data.month),
    datasets: [
        {
            label: "Chats",
            data: data.map((data) => data.chats),
            backgroundColor: data.map(data => data.active ? "#51ABB3" : "#314A84"),
            borderRadius: 4
        },
    ]
}

export default function BarChart() {
    return (
        <Bar
            data={chartData}
            options={{
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: true,
                        backgroundColor: '#F1F5F9',
                        bodyColor: '#F1F5F9',
                        titleColor: "#0F172A",
                        borderColor: '#F1F5F9',
                        borderWidth: 1,
                        titleFont: { weight: 'bold' },
                        callbacks: {
                            labelTextColor: function (context) {
                                return '#0F172A';
                            }
                        }
                    }
                }, scales: {
                    x: {
                        grid: { display: false }
                    },
                    y: {
                        grid: { borderDash: [5, 5], borderColor: "transparent", tickColor: "transparent" },
                    }
                }, responsive: true
            }}
            {...chartSetting}
        />
    )
}