import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import {getStorageData, removeStorageData} from "../../../framework/src/Utilities";
import { DropResult } from "@hello-pangea/dnd";
import { utils } from "./utils.web";
import { toast } from "react-toastify"

export type Option = {
  id: number;
  name: string;
  value: string;
}

export type ValueChangeArgs = {
  value: string;
  name: string;
}
export type QuestionType = "single_line" | "multiline" | "numerical" | "date" | "dropdown" | "radio" | "checkbox";

export type FormQuestion = {
  id: number;
  questionTitle: string;
  question: string;
  questionError?: string;
  optionError?: string;
  type: QuestionType;
  options?: Option[];
}

export type Practice = {
  label: string;
  value: number;
}

type Organization = {
  id: number;
  organisation_name: string;
}

type QuestionAttribute = {
  question: string;
  question_type: QuestionType;
  position: number;
  options?: string[]
}

export const optionsField = new Set<QuestionType>(["checkbox", "dropdown", "radio"])

const formQuestionTitle: Record<QuestionType, string> = {
  single_line: "Single line",
  multiline: "Multiline",
  numerical: "Numerical",
  date: "Date",
  radio: "Radio",
  checkbox: "Checkbox",
  dropdown: "Dropdown"
}


// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  formName: string;
  formNameError: string | undefined;
  formQuestions: FormQuestion[];
  selectedPracticeValue: number[];
  selectPracticeError?: string;
  isGlobal: boolean;
  practices: Practice[];
  saveFormLoading: boolean;
  showOptionError: boolean;
  showPracticeError: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AutomaticFormCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  practiceApiCallId: string = "";
  saveForm: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      // Customizable Area Start
      token: "",
      formName: "",
      formNameError: undefined,
      formQuestions: [],
      selectedPracticeValue: [],
      selectPracticeError: undefined,
      isGlobal: false,
      practices: [],
      saveFormLoading: false,
      showOptionError: false,
      showPracticeError: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start

    this.handleRestApiResponse = this.handleRestApiResponse.bind(this)
    this.handleFetchPracticeData = this.handleFetchPracticeData.bind(this)
    this.handlePracticeDataResponse = this.handlePracticeDataResponse.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handleSaveFormResponse = this.handleSaveFormResponse.bind(this)
    this.onFormCloseHandler = this.onFormCloseHandler.bind(this)
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const handlers = {
      [getName(MessageEnum.RestAPIResponceMessage)]: this.handleRestApiResponse
    }

    const handler = handlers[message.id]
    if(handler) {
        handler(message)
    }
    // Customizable Area End
  }

  // Customizable Area Start


  async componentDidMount() {
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    this.setState({token})
    this.handleFetchPracticeData(token)
  }

  handleRestApiResponse(message: Message){
    const responseHandlers: Record<string, (newMsg: Message) => void> = {}

    const {title} = message.getData(getName(MessageEnum.NavigationPropsMessage))

    if(this.practiceApiCallId === title) {
        responseHandlers[this.practiceApiCallId] = this.handlePracticeDataResponse
    }

    if(this.saveForm === title) {
      responseHandlers[this.saveForm] = this.handleSaveFormResponse
    }

    const responseHandler = responseHandlers[title];
    if (responseHandler) {
        responseHandler(message);
    }
  }

  async handleSaveFormResponse(message: Message) {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    this.setState({saveFormLoading: false})
    if(responseJson === undefined) {
      toast.error("Failed to create new form", {className: "error__toast"})
      return;
    }

    if(responseJson.errors?.[0]?.token) {
      return await this.handleLogout()
    }

    if(responseJson.data) {
      toast.success("Your form was successfully created", {className: "success__toast"})
      this.onFormCloseHandler()
    }
  }

  handleFetchPracticeData(token: string){
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const newRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    
    newRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    newRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPracticeData
    )
    newRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    )
    newRequestMessage.addData(getName(MessageEnum.NavigationPropsMessage), {title: "Practice"})
    this.practiceApiCallId = "Practice"
    this.send(newRequestMessage)
  }

  async handlePracticeDataResponse(message: Message){
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if(responseJson === undefined) {
      toast.error("Failed to fetch practice data", {className: "error__toast"})
      return;
    }

    if(responseJson.errors?.[0]?.token) {
      return await this.handleLogout()
    }

    if(responseJson.organizations) {
      const newPractices: Practice[] = responseJson.organizations.map((item: Organization) => ({label: item.organisation_name, value: item.id}))
      this.setState({practices: newPractices})
    }
  }

  handleFormNameChange = ({value}: ValueChangeArgs) => {
    this.setState({formName: value})
  }

  handleFormSave = () => {
    const {hasOptionError, hasQuestionError, newFormQuestionsWithError} = utils.getFormQuestionsWithError(this.state.formQuestions)
    const hasFormNameError = this.state.formName.trim().length === 0;
    const hasPracticeError = this.state.selectedPracticeValue.length === 0 && !this.state.isGlobal
    let formNameError: string | undefined = undefined;
    let selectedPracticeError: string | undefined = undefined;

    if(hasFormNameError) {
      formNameError = "Please add a form name"
    }

    if(hasPracticeError) {
      toast.warn("Please select form association", {className: "warn__toast"})
      selectedPracticeError = "Please select form association"
    }

    if(hasOptionError || hasQuestionError || hasFormNameError || hasPracticeError) {
      this.setState({
        formQuestions: newFormQuestionsWithError,
        formNameError: formNameError,
        selectPracticeError: selectedPracticeError,
        showOptionError: hasOptionError,
        showPracticeError: hasPracticeError,
      })
      return;
    }

    const newFormQuestionsWithoutError = this.state.formQuestions.map(item => ({
      ...item,
      questionError: undefined,
      optionError: undefined,
    }))

    const questions_attributes = this.state.formQuestions.map((field, index) => {
      const newField: QuestionAttribute = {
        question: field.question,
        question_type: field.type,
        position: index + 1,
      }

      if(field.options) {
        newField["options"] = field.options.map(option => option.value)
      }

      return newField
    })

    this.setState({
      formQuestions: newFormQuestionsWithoutError, 
      saveFormLoading: true,
      formNameError: undefined,
      selectPracticeError: undefined,
      showOptionError: false,
      showPracticeError: false,
    })

    const body = {
      form: {
        form_name: this.state.formName,
        practice: this.state.selectedPracticeValue.length === 0 ? null : this.state.selectedPracticeValue.join(","),
        is_global: this.state.isGlobal,
        isActive: true,
        questions_attributes,
      },
    }

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    )

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createForm
    )

    reqMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {title: "CreateForm"}
    )

    this.saveForm = "CreateForm"

    this.send(reqMessage)

  }

  handleCloseError = (stateKey: Extract<keyof S, "showOptionError" | "showPracticeError">) => () => {
    this.setState({...this.state,[stateKey]: false})
  }

  handleAddField = (type: QuestionType) => () => {
    let newFormQuestion: FormQuestion = {
      id: Date.now(),
      questionTitle: formQuestionTitle[type],
      question: "",
      questionError: undefined,
      type: type,
      options: undefined,
    }

    if(optionsField.has(type)) {
      newFormQuestion = {
        id: Date.now(),
        questionTitle: formQuestionTitle[type],
        question: "",
        questionError: undefined,
        type: type,
        options: [],
        optionError: undefined,
      }
    } 

    if(optionsField.has(type)) {
      newFormQuestion["options"] = [
        {
          id: 1,
          name: `question_${this.state.formQuestions.length + 1}_option_${1}`,
          value: "Option 1",
        },
        {
          id: 2,
          name: `question_${this.state.formQuestions.length + 2}_option_${2}`,
          value: "Option 2",
        }
      ]
    }

    this.setState({
      formQuestions: [...this.state.formQuestions, newFormQuestion]
    })
  }

  handleFormQuestionValueChange = ({value, name}: ValueChangeArgs) => {
    const currentFormQuestionIndex = (+name.split("_")[1]) - 1
    const newFormQuestions = [...this.state.formQuestions]
    newFormQuestions[currentFormQuestionIndex] = {
      ...newFormQuestions[currentFormQuestionIndex],
      question: value
    }
    this.setState({formQuestions: newFormQuestions})
  }

  handleQuestionDragEnd = (result: DropResult) => {
    if(!result.destination) {
      return;
    }

    if(result.destination.index === result.source.index) {
      return;
    }

    const newFormQuestions = [...this.state.formQuestions]
    const [removed] = newFormQuestions.splice(result.source.index, 1)
    newFormQuestions.splice(result.destination.index, 0, removed)

    this.setState({formQuestions: newFormQuestions})
  }

  handleDeleteQuestion = (questionId: number) => () => {
    const newFormQuestion = this.state.formQuestions.filter(item => item.id !== questionId)
    this.setState({formQuestions: newFormQuestion})
  }

  handleChangeSelectedPractieValue = (value: number[]) => {
    this.setState({selectedPracticeValue: value})
  }

  handleGolbalSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      isGlobal: event.target.checked, 
      selectedPracticeValue: [], 
      selectPracticeError: undefined, 
      showPracticeError: false
    })
  }

  handleUpdateOptionsInQuestion = (questionIndex: number, options: Option[]) => {
    const newFormQuestions = [...this.state.formQuestions]
    newFormQuestions[questionIndex - 1] = {
      ...newFormQuestions[questionIndex - 1],
      options: options
    }
    this.setState({formQuestions: newFormQuestions})
  }

  async handleLogout() {
    await removeStorageData("authToken")
    await removeStorageData("userId")
    sessionStorage.clear()
    const navigationMessage = new Message(getName(MessageEnum.NavigationSignupLoginMessage))
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), {navigation: this.props.navigation})
    this.send(navigationMessage)
  }

  onFormCloseHandler(){
    const redirectTo = sessionStorage.getItem("redirectTo") || "form"
    const botId = sessionStorage.getItem("botId")

    let navigationMessage = new Message(getName(MessageEnum.NavigationFormsMessage))

    if(redirectTo === "bot" && botId) {
      navigationMessage = new Message(getName(MessageEnum.NavigationIndividualBotMessage))
      navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), botId)
    }
    sessionStorage.removeItem("redirectTo")
    sessionStorage.removeItem("botId")

    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {navigation: this.props.navigation}
    )

    this.send(navigationMessage)

  }

  // Customizable Area End
}
